export interface FieldBase {
    helpText?: string;
    id: string;
    label: string;
    name: string;
    required?: boolean;
    placeholder?: string;
    tailwindStyle?: string;
    borderStyle?: string;
    selectOptions?: string[];
    hiddenLabel?: boolean;
    maxLength?: number;
}

interface DescribedByText {
    helpText?: string;
    errorText?: string | boolean | undefined;
}

export const getFieldId = (id: string, fieldName: string) => `${id}__${fieldName}`;
export const getErrorId = (id: string) => `${id}__error`;
export const getHelpTextId = (id: string) => `${id}__helpText`;

export const getDescribedby = (id: string, { errorText, helpText }: DescribedByText): string => {
    const describedBy = [];

    if (errorText) {
        describedBy.push(getErrorId(id));
    }

    if (helpText) {
        describedBy.push(getHelpTextId(id));
    }

    return describedBy.join(' ');
};
