import 'react-datepicker/dist/react-datepicker.css';

// Local
import { FieldBase, getDescribedby, getErrorId } from './form-base';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
// Global
import { useField, useFormikContext } from 'formik';

import DatePicker from 'react-datepicker';

interface FieldInputProps extends FieldBase {
    autoComplete?: 'given-name' | 'family-name' | 'email' | 'new-password' | 'current-password';
    hiddenLabel?: boolean;
    type?: 'date' | 'text' | 'email' | 'password' | 'checkbox' | 'select';
    variant?: 'primary' | 'inverted';
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldInput = forwardRef(
    (
        {
            borderStyle,
            hiddenLabel,
            id,
            label,
            name,
            placeholder,
            required,
            selectOptions,
            tailwindStyle: style,
            type = 'text',
            variant = 'primary',
            maxLength,
            onChange,
        }: FieldInputProps,
        ref: ForwardedRef<any>
    ) => {
        const [field, meta] = useField(name);
        const [showLabel, setShowLabel] = useState(false);
        const [isDatePickerOpen, setIsDatePickerOpen] = useState(() => false);
        const svgRef = useRef<SVGSVGElement>(null);

        const { setFieldValue, setFieldTouched } = useFormikContext();

        const errorText = meta.touched && meta.error;

        let borderColor = 'border-CoolGray border-2';

        if (errorText) {
            borderColor = variant === 'primary' ? 'border-Red border-2' : 'border-white border-2';
        }

        useEffect(() => {
            if (
                field.value?.length > 0 ||
                (typeof field.value?.getMonth === 'function' && field.value)
            ) {
                setShowLabel(true);
            } else {
                setShowLabel(false);
            }
        }, [field.value]);

        return (
            <div className="w-full">
                <div
                    className={`relative inline-block w-full ${
                        type === 'checkbox' ? 'border-none' : `${borderStyle || ''} ${borderColor}`
                    }`}
                >
                    {showLabel && label && (
                        <label
                            className="absolute left-[10px] top-[-8px] z-[1] bg-white px-2 text-xs font-bold text-ToneBlue flex items-start"
                            htmlFor={id}
                        >
                            <span className="flex">{label.replace('*', '')}</span>
                            {required && <span className="text-Red flex scale-125">&nbsp;*</span>}
                        </label>
                    )}
                    {type === 'checkbox' && (
                        <div className="flex items-center">
                            <label className={`pr-3 ${hiddenLabel ? 'hidden' : ''}`} htmlFor={id}>
                                {label}
                            </label>
                            <input
                                {...field}
                                aria-describedby={getDescribedby(id, { errorText })}
                                aria-required={required}
                                id={id}
                                name={name}
                                onFocus={() => setFieldTouched(name, true, false)}
                                type={type}
                                className={type === 'checkbox' ? 'mr-3 h-10 w-10' : ''}
                                checked={!!field.value}
                            />
                        </div>
                    )}
                    {type === 'date' && (
                        <div className="relative w-full customDatePickerWidth">
                            <DatePicker
                                {...field}
                                aria-describedby={getDescribedby(id, { errorText })}
                                aria-required={required}
                                className={`${style} pr-16`}
                                id={id}
                                name={name}
                                onBlur={(e) => {
                                    if (!field.value) {
                                        setShowLabel(false);
                                    }
                                }}
                                onChange={(date: Date) => {
                                    setFieldValue(name, date || '');
                                    setShowLabel(true);
                                    setIsDatePickerOpen(false);
                                }}
                                onClickOutside={(e) => {
                                    if (e.target !== svgRef.current) {
                                        setIsDatePickerOpen(false);
                                    }
                                }}
                                onFocus={() => {
                                    setFieldTouched(name, true, false);
                                    setShowLabel(true);
                                    setIsDatePickerOpen(true);
                                }}
                                onInputClick={() => {
                                    setIsDatePickerOpen(true);
                                }}
                                onSelect={() => setShowLabel(true)}
                                placeholderText={!showLabel ? placeholder : ''}
                                selected={field.value}
                                open={isDatePickerOpen}
                            />
                            <svg
                                className="absolute right-0 top-1 h-16 w-16 p-5 text-DarkGray cursor-pointer"
                                fillRule="evenodd"
                                style={{ fill: 'none', stroke: 'currentcolor' }}
                                viewBox="0 0 16 15"
                                xmlns="http://www.w3.org/2000/svg"
                                ref={svgRef}
                                onClick={() => {
                                    setIsDatePickerOpen((prevIsOpen) => !prevIsOpen);
                                }}
                            >
                                <path d="m.5,5.5h15" />
                                <path d="m14,2.5H2c-.4,0-.78.16-1.06.44-.28.28-.44.66-.44,1.06v9c0,.4.16.78.44,1.06.28.28.66.44,1.06.44h12c.4,0,.78-.16,1.06-.44.28-.28.44-.66.44-1.06V4c0-.4-.16-.78-.44-1.06-.28-.28-.66-.44-1.06-.44h0Z" />
                                <path d="m4.5.5v2" />
                                <path d="m11.5.5v2" />
                            </svg>
                        </div>
                    )}
                    {type === 'select' && (
                        <select
                            className={`appearance-none ${style}`}
                            {...field}
                            aria-describedby={getDescribedby(id, { errorText })}
                            aria-required={required}
                            placeholder={!showLabel ? placeholder : ''}
                            id={id}
                            name={name}
                            onFocus={(e) => {
                                e.stopPropagation();
                                setFieldTouched(name, true, false);
                                setShowLabel(true);
                            }}
                            onBlur={() => {
                                if (field.value?.length < 1) {
                                    setShowLabel(false);
                                }
                            }}
                        >
                            {placeholder && (
                                <option disabled value="">
                                    {placeholder}
                                </option>
                            )}
                            {selectOptions?.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    )}
                    {type !== 'select' && type !== 'date' && type !== 'checkbox' && (
                        <input
                            className={style}
                            {...field}
                            onChange={onChange || field.onChange}
                            aria-describedby={getDescribedby(id, { errorText })}
                            aria-required={required}
                            placeholder={!showLabel ? placeholder : ''}
                            id={id}
                            name={name}
                            type={type}
                            maxLength={maxLength}
                            onFocus={() => {
                                setFieldTouched(name, true, false);
                                setShowLabel(true);
                            }}
                            onBlur={() => {
                                if (field.value?.length < 1) {
                                    setShowLabel(false);
                                }
                            }}
                            ref={ref}
                        />
                    )}
                </div>
                {errorText && (
                    <p
                        className={`font-bold ${variant === 'primary' ? 'text-Red' : 'text-white'}`}
                        id={getErrorId(id)}
                    >
                        {errorText}
                    </p>
                )}
            </div>
        );
    }
);

export default FieldInput;
