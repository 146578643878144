import { header } from '../../constants/typography';

const Banner = ({ message }: { message: string }) => (
    <div className="items-center md:h-fit md:w-fit">
        <div className="">
            <div className="space-y-2">
                <div>
                    <h2
                        className={`${header} capitalize leading-10 text-DarkBlue md:leading-normal`}
                    >
                        Search Result
                    </h2>
                </div>
                <p className="leading-22 text-TextDarkGrayComp">{message}</p>
            </div>
        </div>
    </div>
);

export default Banner;
