import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ErrorBoundary from './ErrorBoundary';
import IDCardList from './components/IDCardList/IDCardList';
import { IdCardProvider } from './context/IdCardContext';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { MemberProvider } from './context/MemberContext';
import MemberSearch from './containers/MemberSearch';
import { Suspense, useEffect } from 'react';
import TechnicalIssueFullScreen from './components/TechnicalIssue/TechnicalIssueFullScreen';
import httpClient from './utils/axios';
import { queryRetry } from './constants/react-query';

const TEN_MINUTES = 10 * 60 * 1000;

function App({ data }: { data: string | null }) {
    useEffect(() => {
        if (data) {
            httpClient.interceptors.request.use((request) => {
                const authToken = data;

                if (request.headers) {
                    request.headers.Authorization = `Bearer ${authToken}`;
                }

                return request;
            });
        }
    }, [data]);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { retry: queryRetry, staleTime: TEN_MINUTES, refetchOnWindowFocus: false },
        },
    });

    return (
        <ErrorBoundary
            fallbackRender={<TechnicalIssueFullScreen errorMessage="Unknown application error" />}
        >
            <QueryClientProvider client={queryClient}>
                <MemberProvider>
                    <IdCardProvider>
                        <div>
                            <Suspense fallback={<LoadingSpinner />}>
                                <div className="flex flex-col lg:flex-row gap-0 md:gap-6 w-full">
                                    <MemberSearch />
                                    <div className="flex flex-col gap-y-6 w-full lg:w-9/12 max-w-[1140px] !p-4">
                                        <IDCardList />
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </IdCardProvider>
                </MemberProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
