import { IdCardContext } from '../../context/IdCardContext';
import { b64toBlob } from '../../util';
import { useContext } from 'react';

const PrintIDCard = () => {
    const idCard = useContext(IdCardContext);

    const printIDCard = (e: any) => {
        e.preventDefault();

        if (idCard.base64Image) {
            const blob = b64toBlob(idCard.base64Image, 'image/jpeg');
            const blobUrl = URL.createObjectURL(blob);
            const myWindow = window.open(blobUrl, '_blank');
            myWindow?.print();
        } else {
            console.warn('no ID cards available for printing');
        }
    };

    return (
        <p className="font-bold">
            Please click the link to print your ID Card.
            <button onClick={printIDCard}>
                <span>&nbsp;</span>
                <span className="text-PrimaryBlue border-none bg-none !p-0 underline">Print</span>
            </button>
        </p>
    );
};

export default PrintIDCard;
