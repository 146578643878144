import { MemberContext } from '../context/MemberContext';
import { getErrorMessage } from '../utils/errorHandler';
import { highmarkContactInfoOutput } from '../schemas';
import httpClient from '../utils/axios';
import { queryRetry } from '../constants/react-query';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

type GetContactInfoParams = {
    memberId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

export const getContactInfo = async ({
    memberId,
    firstName,
    lastName,
    dateOfBirth,
}: GetContactInfoParams) => {
    try {
        const { data } = await httpClient.request({
            method: 'get',
            url: '/contact',
            params: {
                memberId,
                firstName,
                lastName,
            },
            headers: {
                Accept: 'application/json',
                'X-DOB': dateOfBirth,
            },
        });
        const addressData = data.address;

        if (!addressData) throw new Error('Address data not found for user');

        return addressData;
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

export const useContactInfo = (queryOptions: { enabled: boolean }) => {
    const { enabled } = queryOptions;
    const { member } = useContext(MemberContext);
    const params = {
        memberId: member?.umi || '',
        firstName: member?.firstName || '',
        lastName: member?.lastName || '',
        dateOfBirth: member?.dateOfBirth || '',
    };

    return useQuery({
        queryKey: ['getContactInfo', params],
        queryFn: () => {
            if (!member) throw new Error('useContactInfo: Member not found');
            return getContactInfo(params);
        },
        retry: queryRetry,
        enabled,
    });
};
