import SuccessCheck from '../SuccessCheck/SuccessCheck';

const SuccessBlock = ({ successMessage }: { successMessage: string }) => (
    <div className="text-base leading-6 text-black">
        <div className="flex items-center">
            <SuccessCheck className="mr-2" />
            <span>{successMessage} </span>
        </div>
    </div>
);

export default SuccessBlock;
