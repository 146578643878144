import { Form, Formik } from 'formik';

import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../Carousel/CarouselItem';
import { BaseIdCardProps } from '../OrderIdCard/types';
import ErrorBlock from '../Indicators/Error';
import FieldInput from '../FieldInput/FieldInput';
import SuccessBlock from '../Indicators/Success';
import { getFieldId } from '../FieldInput/form-base';
import { header } from '../../constants/typography';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useEmailIDCard } from '../../api/emailIDCard';
import { useState } from 'react';
import { z } from 'zod';
import ConfirmationModal from '../Modal/ConfirmationModal';

const Schema = z.object({
    email: z.string().email({ message: 'Invalid email format' }),
});

const successText = 'ID Card Sucessfully Emailed';

const EmailIDCard = ({ setAction }: BaseIdCardProps) => {
    const [isConfirmationModalShown, setIsConfirmationModalShown] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { mutateAsync } = useEmailIDCard();

    return (
        <div className="max-w-[1040px] h-full">
            <h1 className={`${header} mb-2.5 text-DarkBlue`}>Email ID Card</h1>
            <Formik
                validateOnBlur={false}
                validateOnMount={true}
                validateOnChange={true}
                validationSchema={toFormikValidationSchema(Schema)}
                initialValues={{
                    email: '',
                }}
                onSubmit={async (values, { resetForm }) => {
                    setSuccessMessage('');
                    setErrorMessage('');
                    setIsConfirmationModalShown(false);

                    const { email } = values;
                    try {
                        await mutateAsync(email);
                        setSuccessMessage(successText);
                        setTimeout(() => {
                            resetForm();
                            setSuccessMessage('');
                            setAction('');
                        }, 3000);
                    } catch (e) {
                        console.error(e);
                        setErrorMessage(
                            'We are unable to complete your request at this time. Please contact customer service if the issue persists.'
                        );
                    }
                }}
            >
                {({ isSubmitting, submitForm, resetForm, dirty, isValid, values }) => (
                    <>
                        <Form
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    setIsConfirmationModalShown(true);
                                }
                            }}
                        >
                            <div className="items-center flex flex-col gap-6 w-full md:w-1/2">
                                <FieldInput
                                    id={getFieldId('address', 'email')}
                                    label="*Email To: "
                                    placeholder="*Email Address"
                                    name="email"
                                    required
                                    type={'email'}
                                    tailwindStyle={
                                        'w-full pt-4 pr-3 pb-3 pl-4 outline-2 outline-ToneBlue font-bold text-DarkGray placeholder-DarkGray'
                                    }
                                />
                                <div className="flex flex-col md:flex-row w-full gap-2">
                                    <Button
                                        type="button"
                                        handleClick={() => setIsConfirmationModalShown(true)}
                                        label="Submit"
                                        variant="primary"
                                        isLoading={isSubmitting}
                                        disabled={isSubmitting || !dirty || !isValid}
                                    />
                                    <Button
                                        type="reset"
                                        label="cancel"
                                        variant="secondary"
                                        handleClick={() => {
                                            setAction('');
                                            resetForm();
                                        }}
                                    />
                                </div>
                            </div>
                            <ConfirmationModal
                                action="email"
                                providedValue={values.email}
                                onSubmit={submitForm}
                                open={isConfirmationModalShown}
                                onClose={() => setIsConfirmationModalShown(false)}
                            />
                        </Form>
                        <Carousel className="pt-4">
                            <CarouselItem showChildren={!!errorMessage}>
                                <ErrorBlock errorMessage={errorMessage} />
                            </CarouselItem>
                            <CarouselItem showChildren={!!successMessage}>
                                <SuccessBlock successMessage={successMessage} />
                            </CarouselItem>
                        </Carousel>
                    </>
                )}
            </Formik>
        </div>
    );
};
export default EmailIDCard;
