const ErrorMark = (props: { className: string }) => {
    return (
        <svg
            className={'flex-no-shrink h-5 w-5 fill-Red stroke-Red ' + props.className}
            viewBox="0, 0, 18, 18"
        >
            <circle cx="9" cy="9" r="8.5" fill="none" />
            <path fill="none" strokeWidth="2" d="m9 3 l0 7" />
            <circle cx="9" cy="12.5" r="1.5" stroke="none" />
        </svg>
    );
};

export default ErrorMark;
