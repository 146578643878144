// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    /* .id-card--front {
    clip-path: inset(0 0 57% 0);
  }
  .id-card--back {
    clip-path: inset(57% 0 0 0);
  } */

    .flip-card {
        aspect-ratio: 1.64 / 1;
        background-color: transparent;
        height: 100%;
        perspective: 1000px;
    }

    .flip-card .inner {
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 0.8s;
        width: 100%;
    }

    .flip-card .inner .front,
    .flip-card .inner .back {
        -webkit-backface-visibility: hidden;
        align-items: center;
        backface-visibility: hidden;
        background-color: #ffffff;
        background-size: cover;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
    }

    .flip-card .inner .back {
        background-position-x: center;
        background-position-y: -115%;
        background-repeat: repeat-y;
        background-size: 86%;
        color: white;
        transform: rotateY(180deg);
    }

    .flip-card.isActive .inner {
        transform: rotateY(180deg);
    }
`, "",{"version":3,"sources":["webpack://./src/components/IDCardList/IDCardList.styles.css"],"names":[],"mappings":";IAGI;;;;;KAKC;;IAED;QACI,sBAAsB;QACtB,6BAA6B;QAC7B,YAAY;QACZ,mBAAmB;IACvB;;IAEA;QACI,YAAY;QACZ,kBAAkB;QAClB,4BAA4B;QAC5B,0BAA0B;QAC1B,WAAW;IACf;;IAEA;;QAEI,mCAAmC;QACnC,mBAAmB;QACnB,2BAA2B;QAC3B,yBAAyB;QACzB,sBAAsB;QACtB,aAAa;QACb,sBAAsB;QACtB,YAAY;QACZ,uBAAuB;QACvB,kBAAkB;QAClB,WAAW;IACf;;IAEA;QACI,6BAA6B;QAC7B,4BAA4B;QAC5B,2BAA2B;QAC3B,oBAAoB;QACpB,YAAY;QACZ,0BAA0B;IAC9B;;IAEA;QACI,0BAA0B;IAC9B","sourcesContent":["@tailwind components;\n\n@layer components {\n    /* .id-card--front {\n    clip-path: inset(0 0 57% 0);\n  }\n  .id-card--back {\n    clip-path: inset(57% 0 0 0);\n  } */\n\n    .flip-card {\n        aspect-ratio: 1.64 / 1;\n        background-color: transparent;\n        height: 100%;\n        perspective: 1000px;\n    }\n\n    .flip-card .inner {\n        height: 100%;\n        position: relative;\n        transform-style: preserve-3d;\n        transition: transform 0.8s;\n        width: 100%;\n    }\n\n    .flip-card .inner .front,\n    .flip-card .inner .back {\n        -webkit-backface-visibility: hidden;\n        align-items: center;\n        backface-visibility: hidden;\n        background-color: #ffffff;\n        background-size: cover;\n        display: flex;\n        flex-direction: column;\n        height: 100%;\n        justify-content: center;\n        position: absolute;\n        width: 100%;\n    }\n\n    .flip-card .inner .back {\n        background-position-x: center;\n        background-position-y: -115%;\n        background-repeat: repeat-y;\n        background-size: 86%;\n        color: white;\n        transform: rotateY(180deg);\n    }\n\n    .flip-card.isActive .inner {\n        transform: rotateY(180deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
