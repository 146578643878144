import { isAxiosError } from 'axios';

type ErrorWithMessage = {
    message: string;
};

/** necessary for checking duplicate user error */
export interface ErrorWithCode {
    code: string;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
    if (isErrorWithMessage(maybeError)) return maybeError;

    try {
        return new Error(JSON.stringify(maybeError));
    } catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError));
    }
}

// call this method to derive the message from a caught error object
export function getErrorMessage(error: unknown) {
    let message = 'An unknown error occurred';
    if (isAxiosError(error) && error.response?.data.message) {
        message = error.response.data.message;
    } else {
        message = toErrorWithMessage(error).message;
    }
    return message;
}
