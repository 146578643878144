import './index.css';

import App from './App';
import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import reportWebVitals from './reportWebVitals';

interface DrupalSettings {
    connect_components: {
        accessToken: string | null;
    };
}

declare const drupalSettings: DrupalSettings;

const root = ReactDOM.createRoot(document.getElementById('connect-id-card') as HTMLElement);
root.render(
    // @ts-ignore - drupalSettings is a global variable
    <StrictMode>
        <App
            data={
                process.env.REACT_APP_STAGE === 'local'
                    ? ''
                    : drupalSettings.connect_components.accessToken || ''
            }
        />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
