import React, { useEffect } from 'react';

export const useOnClickOutside = (
    refProp: React.RefObject<HTMLElement>,
    handler: (event: any) => void,
    listenerObject?: React.RefObject<HTMLElement>
) => {
    useEffect(() => {
        const el = refProp?.current;

        const listener = (e: any) => {
            e.preventDefault();
            // Do nothing if clicking ref's element or descendent(nested) elements
            if (!el || el.contains(e.target as Node)) {
                return;
            }
            handler(e);
        };

        if (listenerObject) {
            listenerObject.current?.addEventListener('click', listener);
        } else {
            document.addEventListener('click', listener);
        }

        return () => {
            if (listenerObject) {
                listenerObject.current?.removeEventListener('click', listener);
            } else {
                document.removeEventListener('click', listener);
            }
        };
    }, [refProp, listenerObject, handler]);
};
