import ErrorMark from '../ErrorMark/ErrorMark';

const ErrorBlock = ({ errorMessage }: { errorMessage: string }) => (
    <div className="text-base leading-6 text-black">
        <div className="flex items-center">
            <ErrorMark className="mr-2" />
            <span className="font-bold text-Red">{errorMessage}</span>
        </div>
    </div>
);

export default ErrorBlock;
