const Down = ({ className }: { className: string }) => {
    return (
        <svg
            className={className}
            width="29"
            height="17"
            viewBox="0 0 29 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="down-title"
        >
            <title id="down-title">Down arrow</title>
            <path
                d="M27.6256 0.916805C27.2388 0.530146 26.7143 0.312933 26.1674 0.312933C25.6205 0.312933 25.096 0.530146 24.7092 0.916805L14.4999 11.1262L4.29049 0.916805C3.9015 0.541104 3.38051 0.333216 2.83973 0.337915C2.29895 0.342614 1.78165 0.559525 1.39924 0.941929C1.01684 1.32433 0.799931 1.84163 0.795231 2.38242C0.790531 2.9232 0.998419 3.44419 1.37412 3.83318L13.0417 15.5007C13.4285 15.8874 13.953 16.1046 14.4999 16.1046C15.0468 16.1046 15.5713 15.8874 15.9581 15.5007L27.6256 3.83318C28.0123 3.4464 28.2295 2.92189 28.2295 2.37499C28.2295 1.82809 28.0123 1.30358 27.6256 0.916805Z"
                fill="#fff"
            />
        </svg>
    );
};

export default Down;
