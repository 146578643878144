import GearIcon from '../../assets/GearIC.svg';

const Gear = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53.981"
            height="54.944"
            viewBox="0 0 53.981 54.944"
            className={className}
            aria-labelledby="gear-icon"
        >
            <title id="gear-icon">Gear icon</title>
            <g id="gear_ic" transform="translate(1.708 1.3)">
                <g id="settings">
                    <circle
                        id="Ellipse_285"
                        data-name="Ellipse 285"
                        cx="6.979"
                        cy="6.979"
                        r="6.979"
                        transform="translate(18.303 19.193)"
                        fill="none"
                        stroke="#0094d7"
                        stroke-linecap="square"
                        strokeWidth="2.6"
                    />
                    <path
                        id="Path_1383"
                        data-name="Path 1383"
                        d="M45.785,27.172a18.633,18.633,0,0,0-.384-3.8l6.473-4.746L46.64,9.549l-7.38,3.228a18.8,18.8,0,0,0-6.543-3.786L31.826,1H21.358l-.89,7.991a19.361,19.361,0,0,0-6.543,3.786L6.544,9.549,1.31,18.622l6.473,4.763a19.164,19.164,0,0,0,0,7.59L1.31,35.721l5.234,9.073,7.38-3.228a18.8,18.8,0,0,0,6.543,3.786l.89,7.991H31.826l.89-7.991a19.361,19.361,0,0,0,6.543-3.786l7.38,3.228,5.234-9.073L45.4,30.958a19.768,19.768,0,0,0,.384-3.786Z"
                        transform="translate(-1.31 -1)"
                        fill="none"
                        stroke="#0094d7"
                        stroke-linecap="square"
                        strokeWidth="2.6"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Gear;
