import React, { ErrorInfo, ReactNode } from 'react';

// import { Error } from "../types/index";
// import ErrorModal from "../components/ErrorModal/ErrorModal";
// import appLogger from "../utils/logger";

interface ErrorBoundaryProps {
    children?: ReactNode;
    fallbackRender?: JSX.Element;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        console.error(error);
        console.error(error.message);
        return {
            hasError: true,
            errorMessage:
                "We're sorry, we are experiencing technical issues at this time. Please refresh the page and try again. If the problem persists, please reach out to technical support. " +
                error.message,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallbackRender ? (
                this.props.fallbackRender
            ) : (
                <>{this.props.children}</>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
