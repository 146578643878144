export const stripNonDigits = (phoneNumber: string) => {
    return phoneNumber.replaceAll(/[^\d]/g, '');
};

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (phoneNumber) {
        phoneNumber = stripNonDigits(phoneNumber);
    }

    //--- Check if phone number is 10 digits
    const usNumber10 = phoneNumber?.match(/^(\d{3})(\d{3})(\d{4})$/);
    //--- Check if phone number is 11 digits - with extension code (1)
    const usNumber11 = phoneNumber?.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (usNumber10) {
        return '(' + usNumber10[1] + ') ' + usNumber10[2] + '-' + usNumber10[3];
    }
    if (usNumber11) {
        return usNumber11[1] + '-' + usNumber11[2] + '-' + usNumber11[3] + '-' + usNumber11[4];
    }
};
