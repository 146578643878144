// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .customDatePickerWidth,
    .customDatePickerWidth > div.react-datepicker-wrapper,
    .customDatePickerWidth
        > div
        .customDatePickerWidth
        > div
        > div.react-datepicker__input-container
        input {
        width: 100%;
    }

    .customDatePickerWidth,
    .customDatePickerWidth,
    .react-datepicker__tab-loop,
    .react-datepicker-popper,
    .react-datepicker,
    .react-datepicker__month-container,
    .react-datepicker__header,
    .react-datepicker__month {
        width: 100%;
    }

    .customDatePickerWidth > .react-datepicker > .react-datepicker__week {
        width: 100%;
        min-height: 100px;
    }

    .react-datepicker > .react-datepicker__day {
        /* width: 14% !important; */
        /* margin: 0rem 1.2rem; */
        font-size: 14px !important;
    }
`, "",{"version":3,"sources":["webpack://./src/components/FieldInput/FieldInput.styles.css"],"names":[],"mappings":";IAGI;;;;;;;;QAQI,WAAW;IACf;;IAEA;;;;;;;;QAQI,WAAW;IACf;;IAEA;QACI,WAAW;QACX,iBAAiB;IACrB;;IAEA;QACI,2BAA2B;QAC3B,yBAAyB;QACzB,0BAA0B;IAC9B","sourcesContent":["@tailwind components;\n\n@layer components {\n    .customDatePickerWidth,\n    .customDatePickerWidth > div.react-datepicker-wrapper,\n    .customDatePickerWidth\n        > div\n        .customDatePickerWidth\n        > div\n        > div.react-datepicker__input-container\n        input {\n        width: 100%;\n    }\n\n    .customDatePickerWidth,\n    .customDatePickerWidth,\n    .react-datepicker__tab-loop,\n    .react-datepicker-popper,\n    .react-datepicker,\n    .react-datepicker__month-container,\n    .react-datepicker__header,\n    .react-datepicker__month {\n        width: 100%;\n    }\n\n    .customDatePickerWidth > .react-datepicker > .react-datepicker__week {\n        width: 100%;\n        min-height: 100px;\n    }\n\n    .react-datepicker > .react-datepicker__day {\n        /* width: 14% !important; */\n        /* margin: 0rem 1.2rem; */\n        font-size: 14px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
