// Local
import { ValuesType } from '../OrderIdCard/types';
import { useEffect, useRef } from 'react';
// Global
import { useFormikContext } from 'formik';
import FieldInput from './FieldInput';

type GoogleFieldProps = {
    id: string;
    tailwindStyle: string;
    label: string;
    placeholder: string;
    name: string;
};

const options = {
    componentRestrictions: { country: ['us', 'ca'] },
    fields: ['address_components', 'geometry', 'name'],
    types: ['address'],
};

const GooglePlaceField = ({ id, tailwindStyle, label, placeholder, name }: GoogleFieldProps) => {
    const { setFieldValue } = useFormikContext();
    const placeRef = useRef<any>(null);

    useEffect(() => {
        let autoComplete: google.maps.places.Autocomplete | undefined = undefined;

        if (window.google) {
            autoComplete = new window.google.maps.places.Autocomplete(placeRef.current, options);
        }

        placeRef.current?.focus();

        const handlePlace = () => {
            const place = autoComplete?.getPlace();

            let address1 = '';
            let postcode = '';

            if (place?.address_components) {
                for (const component of place?.address_components) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component?.types[0];

                    switch (componentType) {
                        case 'street_number': {
                            address1 = `${component?.long_name} ${address1}`;
                            break;
                        }

                        case 'route': {
                            address1 += component?.short_name;
                            break;
                        }

                        case 'postal_code': {
                            postcode = `${component?.long_name}${postcode}`;
                            break;
                        }

                        case 'locality':
                            setFieldValue('City', component?.long_name);
                            break;

                        case 'administrative_area_level_1': {
                            setFieldValue('State', component?.short_name as ValuesType['State']);
                            break;
                        }

                        default:
                            console.warn('Unexpected google place component type');
                    }

                    setFieldValue('Address1', address1);
                    setFieldValue('ZipCode', postcode);
                    setFieldValue('Address2', '');
                }
            }
        };

        autoComplete?.addListener('place_changed', handlePlace);
    }, [setFieldValue]);

    return (
        <FieldInput
            id={id}
            label={label}
            placeholder={placeholder}
            name={name}
            required
            type={'text'}
            tailwindStyle={tailwindStyle}
            ref={placeRef}
        ></FieldInput>
    );
};

export default GooglePlaceField;
