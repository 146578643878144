import AnimateGrid from '../animate/Animate';

interface CarouselItemProps {
    children: JSX.Element;
    showChildren: boolean;
}

const CarouselItem = ({ children, showChildren }: CarouselItemProps) => {
    return <AnimateGrid showChildren={showChildren}>{children}</AnimateGrid>;
};

export default CarouselItem;
