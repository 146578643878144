import './IDCardList.styles.css';

import { b64toBlob, newLobToDisplay } from '../../util';
import { useContext, useState } from 'react';

import { Action } from '../OrderIdCard/types';
import Banner from '../Banner/Banner';
import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../Carousel/CarouselItem';
import EmailIDCard from '../EmailIDCard/EmailIDCard';
import FaxIDCard from '../FaxIDCard/FaxIDCard';
import { IdCardContext } from '../../context/IdCardContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { MemberContext } from '../../context/MemberContext';
import OrderIDCard from '../OrderIdCard/OrderIDCard';
import PrintIDCard from '../PrintIDCard/PrintIDCard';
import TechnicalIssue from '../TechnicalIssue/TechnicalIssue';
import ZoomIn from '../Icons/ZoomIn';
import { header } from '../../constants/typography';
import { idCardSample } from '../../utils/IdCardSample';
import { isIdCardOption } from './util';
import useResetAction from '../../hooks/useResetAction';

const coverageTypeId = 'coverageTypeId';
const actionsId = 'actionsId';

const IDCardList = () => {
    const [action, setAction] = useState<Action>('');
    const [flipCardIsActive, setFlipCardIsActive] = useState(false);

    const { base64Image, idCardType, setSelectedIdCard, errorMessage } = useContext(IdCardContext);
    const { member, members, hasSearched } = useContext(MemberContext);
    useResetAction(member, setAction);

    const cardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (isIdCardOption(value)) setSelectedIdCard(value);
    };

    return (
        <>
            {member && (
                <>
                    <div>
                        <h1
                            className={`${header} capitalize leading-10 text-DarkBlue md:leading-normal`}
                        >
                            View ID Card
                        </h1>
                        <p className="mb-6 leading-6 text-black">
                            Choose an action if you need to email, fax, print, order, or report an
                            ID card lost or stolen.
                        </p>

                        <div className="gap-6 flex flex-col lg:flex-row">
                            <div id="member-search-results" className="md:w-2/12">
                                {/* I think this needs an outline or something to group it */}
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-col pb-2">
                                        <div className="font-bold text-nowrap text-DarkBlue">
                                            Member Name
                                        </div>
                                        <div>
                                            {member.firstName} {member.lastName}
                                        </div>
                                    </div>
                                    <div className="flex flex-col !py-2">
                                        <div className="font-bold text-DarkBlue">Member ID</div>
                                        <div>{member.umi}</div>
                                    </div>
                                    <div className="flex flex-col !py-2">
                                        <div className="font-bold text-DarkBlue">Group Number</div>
                                        <div>{member.groupNumber[idCardType] || ''}</div>
                                    </div>
                                </div>
                            </div>
                            {/* Coverage type and Actions select */}
                            <div className="flex flex-col lg:w-3/12">
                                <div className="mb-6">
                                    <label
                                        htmlFor={coverageTypeId}
                                        className="mb-1 font-bold leading-6 text-black"
                                    >
                                        Coverage Type
                                    </label>
                                    <select
                                        className="relative w-full appearance-none rounded-none border-2 border-CoolGray bg-[url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+Cgk8cGF0aCBkPSJNNywxMGw1LDVsNS01SDd6IiAvPgo8L3N2Zz4=)] bg-24 bg-right-2.5 bg-no-repeat pb-3 pl-4 pr-12 pt-4 font-bold capitalize text-DarkGray outline-2 outline-ToneBlue"
                                        onChange={cardChange}
                                        id={coverageTypeId}
                                        value={idCardType}
                                    >
                                        {member.lines.map((line) => (
                                            <option
                                                className="capitalize"
                                                key={line.lineOfBusiness}
                                                value={newLobToDisplay(line.lineOfBusiness)}
                                            >
                                                {newLobToDisplay(line.lineOfBusiness)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-7 lg:mb-auto">
                                    <label
                                        htmlFor={actionsId}
                                        className="mb-1 font-bold leading-6 text-black"
                                    >
                                        Actions
                                    </label>
                                    <select
                                        onChange={(e) => {
                                            setAction((e.target.value as Action) || '');
                                        }}
                                        value={action}
                                        className="relative w-full appearance-none rounded-none border-2 border-CoolGray bg-[url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+Cgk8cGF0aCBkPSJNNywxMGw1LDVsNS01SDd6IiAvPgo8L3N2Zz4=)] bg-24 bg-right-2.5 bg-no-repeat pb-3 pl-4 pr-12 pt-4 font-bold capitalize text-DarkGray outline-2 outline-ToneBlue"
                                        id={actionsId}
                                    >
                                        <option value="" disabled>
                                            -Select-
                                        </option>
                                        <option value="order">Order</option>
                                        <option value="email">Email </option>
                                        <option value="fax">Fax</option>
                                        <option value="print">Print </option>
                                        <option value="report">Report Lost or Stolen</option>
                                    </select>
                                </div>
                            </div>

                            {!errorMessage && (
                                <div className="flex items-start gap-2 lg:w-6/12 pt-4">
                                    <div className="flex flex-col w-full lg:w-10/12">
                                        <div
                                            className={`flip-card${
                                                flipCardIsActive ? ' isActive' : ''
                                            }`}
                                        >
                                            <div className="inner">
                                                <div>
                                                    <div
                                                        className={`${
                                                            !base64Image ? 'blur ' : ' '
                                                        }front`}
                                                        style={{
                                                            backgroundImage: `url('data:image/jpeg;base64, ${
                                                                base64Image || idCardSample
                                                            }')`,
                                                        }}
                                                    ></div>
                                                    {!base64Image && (
                                                        <div className="absolute w-full h-full flex justify-center">
                                                            <div className="flex flex-col justify-center">
                                                                <LoadingSpinner
                                                                    showOnMount={true}
                                                                />
                                                                <span className="flex font-bold">
                                                                    Fetching ID Card...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    className="back"
                                                    style={{
                                                        backgroundImage: `url('data:image/jpeg;base64, ${
                                                            base64Image || idCardSample
                                                        }')`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center w-full mt-2">
                                            <div className="max-w-1/2">
                                                <Button
                                                    variant="tertiary"
                                                    handleClick={() => {
                                                        if (base64Image) {
                                                            setFlipCardIsActive(!flipCardIsActive);
                                                        }
                                                    }}
                                                >
                                                    Flip
                                                </Button>
                                                <Button
                                                    variant="tertiary"
                                                    handleClick={() => {
                                                        if (base64Image) {
                                                            const blob = b64toBlob(
                                                                base64Image,
                                                                'image/jpeg'
                                                            );
                                                            const blobUrl =
                                                                URL.createObjectURL(blob);

                                                            window.open(blobUrl, '_blank');
                                                        }
                                                    }}
                                                >
                                                    <div className="mr-2 inline-block align-middle">
                                                        <ZoomIn />
                                                    </div>
                                                    View Larger
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {errorMessage && (
                                <div className="flex">
                                    <TechnicalIssue
                                        containerStyle="border-#CCCCCC flex border bg-white md:my-6 md:mx-6"
                                        errorMessage={errorMessage}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Add-on section for actions */}
                    <Carousel className="pt-8 border-t border-MediumGray">
                        <CarouselItem showChildren={action === 'order'}>
                            <OrderIDCard setAction={setAction} action={action} />
                        </CarouselItem>
                        <CarouselItem showChildren={action === 'email'}>
                            <EmailIDCard setAction={setAction} />
                        </CarouselItem>
                        <CarouselItem showChildren={action === 'fax'}>
                            <FaxIDCard setAction={setAction} />
                        </CarouselItem>
                        <CarouselItem showChildren={action === 'print'}>
                            <PrintIDCard />
                        </CarouselItem>
                        <CarouselItem showChildren={action === 'report'}>
                            <p className="font-bold">
                                Please call Customer Service at 1-800-382-2000, if your card was
                                lost or stolen.
                            </p>
                        </CarouselItem>
                    </Carousel>
                </>
            )}
            {members && members.length > 0 && !member && (
                <Banner message="There are multiple results for the search criteria provided. Please use advanced search to provide additional information to narrow results." />
            )}
            {hasSearched && members.length === 0 && !member && (
                <Banner message="No members were found with the provided information. Please refine your search criteria." />
            )}
        </>
    );
};

export default IDCardList;
