import axios from 'axios';
import config from '../constants/env';

// class AxiosInstance {
//     _axiosInstance = axios.create({
//         baseURL: `${config.experienceApiBaseUrl}`,
//         timeout: 30000,
//     });
//     authToken: string | undefined;
// }

// console.log(`base URL is ${config.experienceApiBaseUrl}`);

const httpClient = axios.create({
    baseURL: `${config.experienceApiBaseUrl}`,
    timeout: 30000,
});

export default httpClient;
