import { ButtonProps } from './types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const Button = (props: ButtonProps): JSX.Element => {
    const {
        children,
        label,
        isLoading = false,
        variant = 'primary',
        disabled: disable,
        hide,
        handleClick,
        type,
    } = props;

    const primary = `bg-ToneBlue border-2 border-ToneBlue text-White no-underline ${
        !disable ? 'hover:bg-DarkBlue hover:border-DarkBlue' : ''
    }`;

    const secondary = `bg-White border-2 border-ToneBlue text-ToneBlue no-underline ${
        !disable ? 'hover:bg-LightBlue hover:border-ToneBlue hover:text-ToneBlue' : ''
    }`;

    const tertiary = ` text-ToneBlue no-underline ${!disable ? 'hover:bg-LightBlue' : ''}`;

    const link = `text-white underline`;

    return (
        <button
            className={`relative min-h-0 w-full rounded-[5px] px-16 py-3 md:py-5 text-center text-sm font-bold uppercase md:w-full
				${disable ? 'opacity-40' : 'active:opacity-80'}
				${variant === 'primary' ? primary : ''}
				${variant === 'secondary' ? secondary : ''}
				${variant === 'tertiary' ? tertiary : ''}
        ${variant === 'link' ? link : ''}
				${hide ? 'hidden' : 'block'}`}
            disabled={disable}
            onClick={handleClick}
            type={type || 'submit'}
            aria-label={label}
        >
            <div>
                {children}
                <span className={isLoading ? 'opacity-0' : 'opacity-100'}>{label}</span>
                {isLoading && (
                    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                        <LoadingSpinner showOnMount={true} />
                    </div>
                )}
            </div>
        </button>
    );
};

export default Button;
