import { ReactNode } from 'react';

import './Modal.styles.css';

interface FadeInProps {
    duration?: number;
    delay?: number;
    children: ReactNode;
}
const FadeIn = ({ duration = 300, delay = 0, children }: FadeInProps) => {
    return (
        <div
            className="fadeIn"
            style={{
                animationDuration: duration + 'ms',
                animationDelay: delay + 'ms',
            }}
        >
            {children}
        </div>
    );
};

export default FadeIn;
