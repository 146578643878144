import { LineOfBusiness } from '../../context/types';
import { IDCardOptions, idCardOptions } from '../../types';

export const isIdCardOption = (value: string): value is IDCardOptions => {
    const idCardOption = idCardOptions.find((option) => option === value);

    if (idCardOption) return true;
    else {
        console.error('Invalid ID Card option');
        throw new Error('Invalid ID Card option');
    }
};

export const getDefaultCoverageType = (lines: LineOfBusiness[]) => {
    let defaultCoverageType: IDCardOptions = 'medical';

    const coverageTypes = lines.map((line) => line.lineOfBusiness.toLowerCase());

    if (coverageTypes.includes('ppo')) {
        defaultCoverageType = 'medical';
    } else if (coverageTypes.includes('den')) {
        defaultCoverageType = 'dental';
    } else if (coverageTypes.includes('vis')) {
        defaultCoverageType = 'vision';
    }

    return defaultCoverageType;
};
