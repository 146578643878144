import { useEffect } from 'react';
import { Member } from '../context/types';
import { Action } from '../components/OrderIdCard/types';

/** Resets ID Card action when a member changes */
const useResetAction = (
    member: Member | undefined,
    setAction: React.Dispatch<React.SetStateAction<Action>>
) => {
    useEffect(() => {
        if (member) setAction('');
    }, [member, setAction]);
};

export default useResetAction;
