import { IdCardContext } from '../context/IdCardContext';
import { getErrorMessage } from '../utils/errorHandler';
import httpClient from '../utils/axios';
import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

type EmailIdCardParams = {
    email: string;
    idCardData: string;
};

export const emailIDCard = async ({ email, idCardData }: EmailIdCardParams) => {
    try {
        await httpClient({
            method: 'post',
            url: '/email',
            data: { method: 'email', value: email, idCardData },
        });
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

export const useEmailIDCard = () => {
    const { base64Image } = useContext(IdCardContext);

    return useMutation({
        mutationKey: ['emailIDCard'],
        mutationFn: (email: string) => emailIDCard({ email, idCardData: base64Image }),
    });
};
