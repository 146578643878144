import { Member } from './types';

interface memberAlreadyInContextParams {
    members: Member[];
    umi: string | undefined;
    groupNumber: string | undefined;
}

export const membersAlreadyInContext = ({
    members,
    umi,
    groupNumber,
}: memberAlreadyInContextParams) => {
    const foundMembers = members.filter((member) => {
        const isUMIinContext = member.umi === umi;
        if (isUMIinContext) return true;

        const isGroupNumberInContext = Object.entries(member.groupNumber).some(
            ([key, value]) => value === groupNumber
        );

        if (isGroupNumberInContext) return true;

        return false;
    });

    return foundMembers;
};
