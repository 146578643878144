// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .fadeIn {
        @media (prefers-reduced-motion: no-preference) {
            animation-fill-mode: backwards;
            animation-name: fadeIn;
        }
    }
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.styles.css"],"names":[],"mappings":";IAGI;QACI;YACI,UAAU;QACd;QACA;YACI,UAAU;QACd;IACJ;;IAEA;QACI;YACI,8BAA8B;YAC9B,sBAAsB;QAC1B;IACJ","sourcesContent":["@tailwind components;\n\n@layer components {\n    @keyframes fadeIn {\n        from {\n            opacity: 0;\n        }\n        to {\n            opacity: 1;\n        }\n    }\n\n    .fadeIn {\n        @media (prefers-reduced-motion: no-preference) {\n            animation-fill-mode: backwards;\n            animation-name: fadeIn;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
