import type { SearchMember } from '../types/types';
import { getErrorMessage } from '../utils/errorHandler';
import httpClient from '../utils/axios';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { MemberContext } from '../context/MemberContext';
import { membersAlreadyInContext } from '../context/util';
import { Member } from '../context/types';

export const getMembers = async (params: SearchMember & { members: Member[] }) => {
    const { firstName, lastName, dateOfBirth, groupNumber, UMI, MBI, members } = params;

    // groupNumber search not implmented in Engen API
    const requestParams = {
        firstName,
        lastName,
        umi: UMI,
        mbi: MBI,
    };

    let foundMembers = [];
    foundMembers = membersAlreadyInContext({ members, umi: UMI, groupNumber });

    if (foundMembers.length > 0) {
        return foundMembers;
    }

    try {
        const { data } = await httpClient.request({
            method: 'get',
            url: '/members',
            params: requestParams,
            headers: {
                Accept: 'application/json',
                'X-DOB': dateOfBirth,
            },
        });

        foundMembers = groupNumber
            ? data.members.filter((m: Member) => m.groupNumber === groupNumber)
            : data.members;
        return foundMembers;
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

/** Although maybe more challenging to implement and "enable" on a page than a mutation,
 * it is more semantically correct and utilizes caching if identical queryParams
 * are passed in to it
 */
export const useGetMembers = (
    queryParams: SearchMember,
    queryOptions: { enabled: boolean; retry?: number }
) => {
    const { members } = useContext(MemberContext);

    const { enabled, retry } = queryOptions;
    return useQuery({
        queryKey: ['getMembers', queryParams], // questionable including "members" here as when members becomes defined, it will redo the search
        queryFn: () => getMembers({ ...queryParams, members }),
        enabled,
        retry: retry || 0,
    });
};
