import { useMemo } from 'react';
import { createContext, useState } from 'react';
import { Member, MemberContextProps } from './types';

export const MemberContext = createContext<MemberContextProps>({
    member: {
        umi: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        groupNumber: {},
        clientId: '',
        active: false,
        effectiveDate: '',
        lines: [],
    },
    members: [],
    hasSearched: false,
    setMember: () => console.warn('member context not initialized'),
    setMembers: () => console.warn('member context not initialized'),
    setHasSearched: () => console.warn('member context not initialized'),
});

export const MemberProvider = ({ children }: any) => {
    const [member, setMember] = useState<Member | undefined>();
    /** stores results from member search in the case that multiple members are returned
     * to prevent a second API call to Engen */
    const [members, setMembers] = useState<Member[]>([]);
    const [hasSearched, setHasSearched] = useState<boolean>(false);

    const value = useMemo(() => {
        return {
            member,
            members,
            hasSearched,
            setMember,
            setMembers,
            setHasSearched,
        };
    }, [hasSearched, member, members]);

    return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};
