import { z, string } from 'zod';

export const formikMemberSchema = z.object({
    firstName: string(),
    lastName: string(),
    month: string().length(2, 'Month must be 2 digits'),
    day: string().length(2, 'Day must be 2 digits'),
    year: string().length(4, 'Year must be 4 digits'),
    searchField: string().optional(),
    searchValue: string().optional(),
});

export const searchMemberSchema = z.object({
    firstName: string(),
    lastName: string(),
    dateOfBirth: string(), // react datepicker uses date, but function params require string
    UMI: string().optional(),
    MBI: string().optional(),
    groupNumber: string().optional(),
});
