import Gear from '../Icons/Gear';

export type TechnicalIssuePropTypes = {
    containerStyle: string;
    errorMessage: string | undefined;
};

const TechnicalIssue = ({
    containerStyle, // This is needed because ID card and Plan Information both have White backgrounds but different y-axis margins
    errorMessage,
}: TechnicalIssuePropTypes) => {
    const displayErrorMessage = () => {
        return errorMessage ? (
            <p className="font-bold tracking-normal text-TextDarkGrayComp">Error: {errorMessage}</p>
        ) : null;
    };

    return (
        <div className="items-center md:h-fit md:w-fit">
            <div className={containerStyle}>
                <div className="my-auto ml-9">
                    <Gear />
                </div>
                <div className="my-6 ml-9 mr-10 space-y-2">
                    <div className="mb-[18px]">
                        <h4 className="text-lg font-bold tracking-normal text-TextDarkGrayComp">
                            Sorry, there&apos;s a technical issue
                        </h4>
                    </div>
                    <p className="leading-22 text-TextDarkGrayComp">
                        This content did not load. Try refreshing your browser or coming back later.
                    </p>

                    {displayErrorMessage()}
                </div>
            </div>
        </div>
    );
};

export default TechnicalIssue;
