import { IdCardContext } from '../context/IdCardContext';
import { getErrorMessage } from '../utils/errorHandler';
import httpClient from '../utils/axios';
import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

type FaxIdCardParams = {
    fax: string;
    idCardData: string;
};

export const faxIdCard = async ({ fax, idCardData }: FaxIdCardParams) => {
    try {
        await httpClient({
            method: 'post',
            url: '/email',
            data: { method: 'fax', value: fax, idCardData },
        });
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

export const useFaxIdCard = () => {
    const { base64Image } = useContext(IdCardContext);

    return useMutation({
        mutationKey: ['faxIdCard'],
        mutationFn: (fax: string) => faxIdCard({ fax, idCardData: base64Image }),
    });
};
