import {
    AddressValidationInstance,
    BaseIdCardProps,
    EngenContactInfoSchemaV2,
    Schema,
    ValuesType,
    states,
} from './types';
import { Form, Formik } from 'formik';
import { header, subHeader } from '../../constants/typography';
import { useEffect, useState } from 'react';

import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../Carousel/CarouselItem';
import ErrorBlock from '../Indicators/Error';
import FieldInput from '../FieldInput/FieldInput';
import SuccessBlock from '../Indicators/Success';
import { getFieldId } from '../FieldInput/form-base';
import { getOrderIDCardData } from './util';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useContactInfo } from '../../api/getContactInfo';
import { useOrderIDCard } from '../../api/orderIDCard';
import ConfirmationModal from '../Modal/ConfirmationModal';
import GooglePlaceField from '../FieldInput/GooglePlaceField';

const inputStyle =
    'w-full font-bold outline-2 outline-offset-1 outline-ToneBlue pb-3 pl-4 pr-3 pt-4 text-DarkGray';

const OrderIDCard = ({ action, setAction }: BaseIdCardProps) => {
    const [isConfirmationModalShown, setIsConfirmationModalShown] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isUsingAlternateAddress, setIsUsingAlternateAddress] = useState(false);
    const [contactInfo, setContactInfo] = useState<EngenContactInfoSchemaV2 | undefined>(undefined);

    const { mutateAsync } = useOrderIDCard();

    const contactInfoQuery = useContactInfo({ enabled: action === 'order' });

    useEffect(() => {
        if (contactInfoQuery.isSuccess && contactInfoQuery.data) {
            setContactInfo(contactInfoQuery.data);
        }
    }, [contactInfoQuery.isSuccess, contactInfoQuery.data]);

    useEffect(() => {
        if (!action || action !== 'order') {
            setIsUsingAlternateAddress(false);
        }
    }, [action]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyAUj8cdOvm6ui5H-Npbfztck1DIObEH-DY&callback=initMap&libraries=places&loading=async';
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div className={`max-w-[1040px]`}>
            <h1 className={`${header} mb-2.4 leading-10 text-DarkBlue`}>Order ID Card(s)</h1>
            <p className="mb-2 leading-10 text-black md:pr-20">
                Click the submit button to order an ID card to the address on file for this member.
                You may also send an ID Card to a different address and this will not change your
                permanent address on file.
            </p>
            <Formik
                enableReinitialize
                validationSchema={toFormikValidationSchema(Schema)}
                initialValues={{
                    Address1: contactInfo?.line1 || '',
                    Address2: contactInfo?.line2 || '',
                    City: contactInfo?.city || '',
                    // TODO:  should really zod parse this to confirm it is part of the state enum
                    State: (contactInfo?.state as ValuesType['State']) || '',
                    ZipCode: contactInfo?.postalCode.substring(0, 5) || '',
                }}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={async (values, actions) => {
                    const { setSubmitting, resetForm } = actions;

                    setApiErrorMessage('');
                    setSuccessMessage('');
                    setIsConfirmationModalShown(false);

                    try {
                        const data = getOrderIDCardData({
                            values,
                        });
                        await mutateAsync({
                            hasAlternateAddress: isUsingAlternateAddress,
                            alternateAddress: data.alternateAddress,
                        });
                        setSuccessMessage('ID Card Sucessfully Ordered');
                        setTimeout(() => {
                            setSuccessMessage('');
                            resetForm();
                            setAction('');
                        }, 3000);
                    } catch (e) {
                        console.error(e);
                        let errorMessage =
                            'We are unable to complete your request at this time. Please contact customer service if the issue persists.';
                        if (e instanceof Error && e.message.includes('Address Error')) {
                            errorMessage = e.message.split(':')[1];
                        }
                        setApiErrorMessage(errorMessage);
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    isSubmitting,
                    values,
                    resetForm,
                    submitForm,
                    validateForm,
                    setFieldTouched,
                    setFieldValue,
                }) => (
                    <>
                        <Form>
                            {!isUsingAlternateAddress && (
                                <div>
                                    <div className="my-8 font-bold text-DarkBlue leading-[27px]">
                                        <p
                                            className={`${
                                                !values.Address1 && !contactInfoQuery.isError
                                                    ? 'h-[24px] w-1/4 animate-pulse bg-[#e3e3e2] rounded mb-2'
                                                    : ''
                                            }`}
                                        >
                                            {values.Address1} {values.Address2}
                                        </p>
                                        <p
                                            className={`${
                                                !values.City && !contactInfoQuery.isError
                                                    ? 'h-[24px] w-1/4 animate-pulse bg-[#e3e3e2] rounded'
                                                    : ''
                                            }`}
                                        >
                                            <span className="capitalize">
                                                {values.City ? `${values.City}, ` : ''}
                                            </span>
                                            {values.State} {values.ZipCode}
                                        </p>
                                        {contactInfoQuery.isError && (
                                            <p className={'h-14 flex items-center'}>
                                                {contactInfoQuery.error.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="my-8">
                                        <Button
                                            label="Send to a different mailing address"
                                            variant="tertiary"
                                            type="button"
                                            handleClick={() => {
                                                setIsUsingAlternateAddress(true);
                                                setFieldValue('Address1', '');
                                                setFieldValue('Address2', '');
                                                setFieldValue('City', '');
                                                setFieldValue('State', '');
                                                setFieldValue('ZipCode', '');
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {isUsingAlternateAddress && (
                                <>
                                    <p
                                        className={`${subHeader} leading-8 mb-3.5 mt-3.5 text-black`}
                                    >
                                        Send to a Different Address
                                    </p>

                                    <div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <GooglePlaceField
                                                id={getFieldId('address', 'Address1')}
                                                tailwindStyle={inputStyle}
                                                label="*Address Line 1"
                                                placeholder="*Address Line 1"
                                                name="Address1"
                                            />
                                            <FieldInput
                                                id={getFieldId('address', 'Address2')}
                                                label="Address Line 2"
                                                placeholder="Address Line 2"
                                                name="Address2"
                                                type={'text'}
                                                tailwindStyle={inputStyle}
                                            />
                                            <FieldInput
                                                id={getFieldId('address', 'City')}
                                                label="*City"
                                                placeholder="*City"
                                                name="City"
                                                required
                                                type={'text'}
                                                tailwindStyle={inputStyle}
                                            />
                                            <FieldInput
                                                id={getFieldId('address', 'State')}
                                                label="*State"
                                                placeholder="*State"
                                                name="State"
                                                required
                                                type={'select'}
                                                selectOptions={states}
                                                tailwindStyle={
                                                    'bg-24 bg-no-repeat bg-right-2.5 bg-[url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+Cgk8cGF0aCBkPSJNNywxMGw1LDVsNS01SDd6IiAvPgo8L3N2Zz4=)] font-bold outline-2 outline-ToneBlue pb-3 pl-4 pr-12 pt-4 relative text-DarkGray w-full'
                                                }
                                            />
                                            <FieldInput
                                                id={getFieldId('address', 'ZipCode')}
                                                label="*Zip Code"
                                                placeholder="*Zip Code"
                                                name="ZipCode"
                                                required
                                                type={'text'}
                                                tailwindStyle={inputStyle}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="flex flex-col md:flex-row w-full md:w-1/2 gap-4 mt-4 pr-2">
                                <Button
                                    label="Submit"
                                    type="button"
                                    handleClick={() => {
                                        validateForm().then((data) => {
                                            if (Object.keys(data).length === 0) {
                                                setIsConfirmationModalShown(true);
                                            } else {
                                                Object.keys(data).forEach((name) => {
                                                    setFieldTouched(name, true);
                                                });
                                            }
                                        });
                                    }}
                                    variant="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                />
                                <Button
                                    type="reset"
                                    label="cancel"
                                    variant="secondary"
                                    handleClick={() => {
                                        setApiErrorMessage('');
                                        setAction('');
                                        resetForm();
                                        setTimeout(() => setIsUsingAlternateAddress(false), 500);
                                    }}
                                />
                            </div>

                            <ConfirmationModal
                                action="order"
                                providedValue={`${values.Address1}, ${values.Address2}${values.Address2 ? ',' : ''} ${values.City}, ${values.State} ${values.ZipCode}`}
                                onSubmit={submitForm}
                                open={isConfirmationModalShown}
                                onClose={() => setIsConfirmationModalShown(false)}
                            />
                        </Form>

                        <Carousel className="pt-4">
                            <CarouselItem showChildren={!!apiErrorMessage}>
                                <ErrorBlock errorMessage={apiErrorMessage} />
                            </CarouselItem>
                            <CarouselItem showChildren={!!successMessage}>
                                <SuccessBlock successMessage={successMessage} />
                            </CarouselItem>
                        </Carousel>

                        {/* {hasAddressSuggestion && (
                            <Formik
                                initialValues={{
                                    useSuggestion: 'suggestion',
                                }}
                                onSubmit={({ useSuggestion }) => {
                                    setIsNewMailingForm(false);

                                    let revisedValues;

                                    if (useSuggestion === 'suggestion') {
                                        revisedValues = {
                                            Address1: suggestion.delivery_line_1,
                                            Address2: '',
                                            City: suggestion.components.city_name as string,
                                            State: suggestion.components
                                                .state_abbreviation as ValuesType['State'],
                                            ZipCode: suggestion.components.zipcode as string,
                                        };
                                    } else {
                                        revisedValues = {
                                            Address1: values.Address1,
                                            Address2: values.Address2,
                                            City: values.City,
                                            State: values.State as ValuesType['State'],
                                            ZipCode: values.ZipCode,
                                        };
                                    }

                                    submitOrder({
                                        values: revisedValues,
                                        onSuccess: () => {
                                            setAction('');
                                            setOrderIDCard(false);
                                            setHasAddressSuggestion(false);
                                        },
                                    });
                                }}
                            >
                                <Form>
                                    <Modal>
                                        <>
                                            <Modal.Body>
                                                <div className="grid grid-cols-1 md:grid-cols-2">
                                                    <div>
                                                        <p className="mb-3.5 font-bold">
                                                            You entered:
                                                        </p>
                                                        <div className="flex gap-4">
                                                            <Field
                                                                className="h-6 w-6 align-middle"
                                                                name="useSuggestion"
                                                                type="radio"
                                                                value="original"
                                                            />
                                                            <div className="mb-2.5 text-base font-light leading-6 text-black">
                                                                <p className="capitalize">
                                                                    {values.Address1}
                                                                </p>
                                                                {values.Address2 && (
                                                                    <p className="capitalize">
                                                                        {values.Address2}
                                                                    </p>
                                                                )}
                                                                <p className="capitalize">{`${values.City}, ${values.State} ${values.ZipCode}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-3.5 font-bold">
                                                            We suggest:
                                                        </p>
                                                        <div className="flex gap-4">
                                                            <Field
                                                                checked
                                                                className="h-6 w-6 align-middle"
                                                                name="useSuggestion"
                                                                type="radio"
                                                                value="suggestion"
                                                            />
                                                            <div>
                                                                <div className="mb-2.5 text-base font-light leading-6 text-black">
                                                                    <p className="capitalize">
                                                                        {suggestion.delivery_line_1}
                                                                    </p>
                                                                    <p className="capitalize">
                                                                        {suggestion.last_line}
                                                                    </p>
                                                                </div>
                                                                {suggestion.analysis.footnotes
                                                                    ?.length && (
                                                                    <ul>
                                                                        {SMARTY_ERROR_CODES.map(
                                                                            (error) => (
                                                                                <li
                                                                                    className={`text-DarkGreen ${
                                                                                        !suggestion.analysis.footnotes?.includes(
                                                                                            error.code
                                                                                        )
                                                                                            ? 'hidden'
                                                                                            : ''
                                                                                    }`}
                                                                                    key={error.code}
                                                                                >
                                                                                    {error.details}
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <>
                                                    <Button
                                                        label="Continue"
                                                        type="submit"
                                                        variant="primary"
                                                    />
                                                </>
                                            </Modal.Footer>
                                        </>
                                    </Modal>
                                </Form>
                            </Formik>
                        )} */}
                    </>
                )}
            </Formik>
        </div>
    );
};

export default OrderIDCard;
