import ZoomInIcon from '../../assets/ZoomIn.svg';

const ZoomIn = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23.6 23.3"
            aria-labelledby="zoom-in-icon"
        >
            <title id="zoom-in-icon">Zoom In Icon</title>
            <defs>
                <style>
                    {`
                .cls-1 {
                    stroke-linecap: round;
                    stroke-width: 1.6px;
                }

                .cls-1, .cls-2 {
                    fill: none;
                    stroke: #0072a7;
                    stroke-linejoin: round;
                }

                .cls-2 {
                    stroke-width: 2px;
                }`}
                </style>
            </defs>
            <g id="zoom-in_ic" data-name="zoom-in ic">
                <path
                    id="Stroke_1"
                    data-name="Stroke 1"
                    className="cls-1"
                    d="m20.43,10.61c0,5.42-4.39,9.81-9.81,9.81S.8,16.04.8,10.61,5.19.8,10.61.8s9.81,4.39,9.81,9.81Z"
                />
                <path
                    id="Stroke_3"
                    data-name="Stroke 3"
                    className="cls-1"
                    d="m18.21,17.91l4.59,4.59"
                />
                <g id="plus">
                    <path
                        id="Stroke_5"
                        data-name="Stroke 5"
                        className="cls-2"
                        d="m10.61,6.69v7.85"
                    />
                    <path
                        id="Stroke_7"
                        data-name="Stroke 7"
                        className="cls-2"
                        d="m6.69,10.61h7.85"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ZoomIn;
