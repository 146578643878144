import { IDCardOptions } from './types';

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const lobToDisplay = (lob: string) => {
    let displayValue = 'Unknown';
    switch (lob) {
        case 'M':
            displayValue = 'Medical';
            break;
        case 'D':
            displayValue = 'Dental';
            break;
        case 'V':
            displayValue = 'Vision';
            break;
        case 'RX':
            displayValue = 'Pharmacy';
            break;
        case 'L':
            displayValue = 'Wellness Programs';
            break;
    }
    return displayValue;
};

// there are many types that map to "medical" health insurance
export const newLobToDisplay = (lob: string) => {
    lob = lob.toLowerCase();

    let displayValue: IDCardOptions = 'medical';
    switch (lob) {
        case 'den':
            displayValue = 'dental';
            break;
        case 'vis':
            displayValue = 'vision';
            break;
        case 'con':
            displayValue = 'consolidated';
            break;
        default:
            displayValue = 'medical';
        // case "pha":
        //   displayValue = "pharmacy";
        //   break;
        // case "L":
        //   displayValue = "Wellness Programs";
        //   break;
    }
    return displayValue;
};
