import { useCallback, useEffect, useRef } from 'react';
import { header } from '../../constants/typography';
import FadeIn from './FadeIn';
import { useOnClickOutside } from '../../hooks/useClickOutside';
import ReactDOM from 'react-dom';

const STYLES = {
    body: 'flex-auto !p-6',
    footer: 'flex gap-5 items-center justify-end !p-6 rounded-b',
    header: 'border-b border-slate-200 border-solid flex items-start justify-between !p-5 rounded-t',
    headerText: `${header} text-black`,
    mask: 'bg-MediumGray/90 fixed flex focus:outline-none inset-0 z-50 items-center justify-center outline-none overflow-x-hidden overflow-y-auto',
};

type ModalBodyProps = {
    children: JSX.Element;
};

type ModalFooterProps = {
    children: JSX.Element;
};

type ModalHeaderProps = {
    children: string;
    handleOnClose: () => void;
};

const Body = ({ children }: ModalBodyProps) => {
    if (!children) return <></>;

    return <div className={STYLES.body}>{children}</div>;
};

const Footer = ({ children }: ModalFooterProps) => {
    if (!children) return <></>;

    return <div className={STYLES.footer}>{children}</div>;
};

const Header = ({ children, handleOnClose }: ModalHeaderProps) => {
    if (!children) return <></>;

    return (
        <div className={STYLES.header}>
            <p className={STYLES.headerText}>{children}</p>
        </div>
    );
};

export interface ModalProps {
    open: boolean;
    children: any;
    style?: any | undefined;
    onClose: () => void;
    disableOutsideClick?: boolean;
    disableEscapeKey?: boolean;
    includeCloseIcon?: boolean;
}

const Modal = ({
    open,
    onClose,
    children,
    disableOutsideClick = false,
    disableEscapeKey = false,
    includeCloseIcon = false,
    style,
}: ModalProps) => {
    const backdropRef = useRef<HTMLDivElement>(null);
    const myRef = useRef<HTMLDivElement>(null);
    const handleClick = useCallback(() => {
        if (!disableOutsideClick) {
            onClose && onClose();
        }
    }, [disableOutsideClick, onClose]);

    useOnClickOutside(myRef, handleClick, backdropRef);

    useEffect(() => {
        // prevent scrolling when modal is Open
        if (open) document.body.style.overflow = 'hidden';
        return () => {
            // back to normal scrolling
            document.body.style.overflow = 'unset';
        };
    }, [open]);

    useEffect(() => {
        if (disableEscapeKey) return;

        const onKeyUp = (e: KeyboardEvent) => {
            if (e.code === 'Escape' && open) {
                onClose();
            }
        };
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [disableEscapeKey, onClose, open]);

    useEffect(() => {
        open && myRef && myRef.current?.focus();
    }, [open]);

    return open
        ? ReactDOM.createPortal(
              <FadeIn>
                  <>
                      <div className="items-center bg-slate-200 opacity-50 flex flex-1 h-full w-full justify-center fixed z-50 top-0 overflow-scroll"></div>
                      <div
                          className="items-center flex flex-1 h-full w-full justify-center fixed z-50 top-0 overflow-scroll"
                          ref={backdropRef}
                      >
                          {children && (
                              <>
                                  <div
                                      ref={myRef}
                                      style={style ?? undefined}
                                      className="flex opacity-100 relative items-center bg-white border-2 border-solid inline-flex flex-col justify-center py-6 md:px-6 md:py-12 m-6"
                                  >
                                      {includeCloseIcon && (
                                          <div className="relative flex self-end top-[-20px] left-[-10px] cursor-pointer">
                                              <div
                                                  className="absolute"
                                                  aria-label="Close button"
                                                  onClick={onClose}
                                              >
                                                  <div>
                                                      <div
                                                          style={{
                                                              fontSize: '2rem',
                                                              transition: 'all 0.1s ease-in',
                                                          }}
                                                          onMouseOver={(e) => {
                                                              e.currentTarget.style.transform =
                                                                  'scale(1.3)';
                                                          }}
                                                          onMouseLeave={(e) =>
                                                              (e.currentTarget.style.transform =
                                                                  'scale(1.0)')
                                                          }
                                                      >
                                                          {/* Close icon */}
                                                          &#x00d7;
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      )}
                                      {children}
                                  </div>
                              </>
                          )}
                      </div>
                  </>
              </FadeIn>,
              document.body
          )
        : null;
};

Modal.Body = Body;
Modal.Footer = Footer;
Modal.Header = Header;

export default Modal;
