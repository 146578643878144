import Button from '../Button/Button';
import { Action } from '../OrderIdCard/types';
import Modal, { ModalProps } from './Modal';

interface ConfirmationModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
    action: Action;
    providedValue: string;
    onSubmit: () => void;
}

const ConfirmationModal = ({
    action,
    providedValue,
    onSubmit,
    open = false,
    onClose,
}: ConfirmationModalProps) => {
    return (
        <Modal open={open} onClose={onClose} disableOutsideClick={true}>
            <>
                <Modal.Body>
                    <div className="text-base text-center font-light leading-10 text-black mb-2">
                        <p>
                            Are you sure you want to {action === 'order' ? 'mail' : action} the
                            member ID card to
                        </p>
                        <p>
                            <span className="font-bold">{providedValue}</span>?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button
                            label="Confirm"
                            type="button"
                            variant="primary"
                            handleClick={onSubmit}
                        />
                        <Button
                            label="Cancel"
                            type="button"
                            variant="secondary"
                            handleClick={onClose}
                        />
                    </>
                </Modal.Footer>
            </>
        </Modal>
    );
};

export default ConfirmationModal;
