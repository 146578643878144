import { ValuesType } from './types';

interface OrderIDCardParams {
    values: ValuesType;
}

export const getOrderIDCardData = ({ values }: OrderIDCardParams) => {
    const data = {
        alternateAddress: {
            line1: values.Address1,
            line2: values.Address2 || '',
            city: values.City,
            state: values.State,
            zipCode: values.ZipCode,
        },
    };

    return data;
};
