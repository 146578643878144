import { useContext, useEffect, useMemo } from 'react';
import { createContext, useState } from 'react';
import { IDCardOptions } from '../types';
import { MemberContext } from './MemberContext';
import { useGetIdCard } from '../api/getIdCard';
import { IdCardContextProps } from './types';
import { getDefaultCoverageType } from '../components/IDCardList/util';
import { queryRetry } from '../constants/react-query';

export const IdCardContext = createContext<IdCardContextProps>({
    base64Image: '',
    idCardType: 'medical',
    reset: () => console.warn('context not initialized'),
    setSelectedIdCard: () => console.warn('context not initialized'),
    errorMessage: '',
});

const defaultIDCards = {
    medical: '',
    dental: '',
    vision: '',
    consolidated: '',
};

/** This context may be unnecessary with the query caching ability on react-query;
 * however, I think this adds backup in the case of settings changes either by us
 * or by that library (like changing staleTime, refetchOnWindowFocus, etc.)
 */
export const IdCardProvider = ({ children }: any) => {
    const [selectedIdCard, setSelectedIdCard] = useState<IDCardOptions | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [idCards, setIdCards] = useState(defaultIDCards);

    const { member } = useContext(MemberContext);

    useEffect(() => {
        const defaultCoverageType = getDefaultCoverageType(member?.lines || []);
        setSelectedIdCard(defaultCoverageType);
    }, [member]);

    const idCardQuery = useGetIdCard(
        {
            idCardType: selectedIdCard || 'medical',
            memberId: member?.umi || '',
            groupNumber: (selectedIdCard && member?.groupNumber[selectedIdCard]) || '',
            firstName: member?.firstName || '',
            lastName: member?.lastName || '',
            dateOfBirth: member?.dateOfBirth || '',
        },
        {
            enabled:
                !!member?.umi &&
                !!selectedIdCard &&
                !idCards[selectedIdCard] &&
                !!member?.groupNumber[selectedIdCard],
            retry: queryRetry,
        }
    );

    useEffect(() => {
        if (idCardQuery.isSuccess && idCardQuery.data && selectedIdCard) {
            setIdCards((prevState) => ({
                ...prevState,
                [selectedIdCard]: idCardQuery.data?.idCardImageData || '',
            }));
            // Engen may return an empty response body without throwing an error
        } else if (idCardQuery.isSuccess && !idCardQuery.data) {
            setErrorMessage('ID Card not found');
        }
    }, [selectedIdCard, idCardQuery.isSuccess, idCardQuery.data]);

    useEffect(() => {
        if (idCardQuery.isError) {
            console.error(idCardQuery.error.message);
            setErrorMessage('Error loading ID Card. Please try again later.');
        } else {
            setErrorMessage('');
        }
    }, [idCardQuery.isError, idCardQuery.error]);

    const value = useMemo(() => {
        return {
            idCardType: selectedIdCard || 'medical',
            base64Image: idCards[selectedIdCard || 'medical'] || '',
            errorMessage,
            reset: () => setIdCards(defaultIDCards),
            setSelectedIdCard,
        };
    }, [idCards, selectedIdCard, errorMessage]);

    return <IdCardContext.Provider value={value}>{children}</IdCardContext.Provider>;
};
