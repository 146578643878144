import { Form, Formik } from 'formik';

import { BaseIdCardProps } from '../OrderIdCard/types';
import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import CarouselItem from '../Carousel/CarouselItem';
import ErrorBlock from '../Indicators/Error';
import FieldInput from '../FieldInput/FieldInput';
import SuccessBlock from '../Indicators/Success';
import { faxSchema } from './schemas';
import { getFieldId } from '../FieldInput/form-base';
import { header } from '../../constants/typography';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useFaxIdCard } from '../../api/faxIDCard';
import { useState } from 'react';
import { formatPhoneNumber, stripNonDigits } from './util';
import ConfirmationModal from '../Modal/ConfirmationModal';
import is from 'date-fns/locale/is/index.js';

const FaxIDCard = ({ setAction }: BaseIdCardProps) => {
    const [isConfirmationModalShown, setIsConfirmationModalShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const { mutateAsync } = useFaxIdCard();

    return (
        <div>
            <h1 className={`${header} text-DarkBlue mb-2.5 leading-10`}>Fax ID Card</h1>
            <div>
                <Formik
                    validateOnBlur={false}
                    validateOnMount={true}
                    validateOnChange={true}
                    validationSchema={toFormikValidationSchema(faxSchema)}
                    initialValues={{
                        fax: '',
                    }}
                    onSubmit={async (values, actions) => {
                        const { resetForm } = actions;
                        setErrorMessage('');
                        setSuccessMessage('');
                        setIsConfirmationModalShown(false);

                        const fax = stripNonDigits(values.fax);

                        try {
                            await mutateAsync(fax);
                            setSuccessMessage('ID Card Sucessfully Faxed');

                            setTimeout(() => {
                                setSuccessMessage('');
                                resetForm();
                                setAction('');
                            }, 3000);
                        } catch (e) {
                            console.error(e);
                            setErrorMessage(
                                'We are unable to complete your request at this time. Please contact customer service if the issue persists.'
                            );
                        }
                    }}
                >
                    {({
                        handleChange,
                        resetForm,
                        submitForm,
                        isSubmitting,
                        dirty,
                        isValid,
                        values,
                    }) => (
                        <>
                            <Form
                                onBlur={(e) => {
                                    const formattedNum = formatPhoneNumber(e.target.value);
                                    if (formattedNum) e.target.value = formattedNum;
                                    handleChange(e);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        setIsConfirmationModalShown(true);
                                    }
                                }}
                            >
                                <div className="items-center flex flex-col gap-6 w-full md:w-1/2">
                                    <FieldInput
                                        id={getFieldId('address', 'fax')}
                                        label="Fax To: "
                                        placeholder="*Fax Number"
                                        name="fax"
                                        required
                                        tailwindStyle={
                                            'w-full pt-4 pr-3 pb-3 pl-4 outline-2 outline-ToneBlue font-bold text-DarkGray placeholder-DarkGray'
                                        }
                                    />
                                    <div className="flex flex-col md:flex-row w-full gap-2">
                                        <Button
                                            type="button"
                                            handleClick={() => {
                                                setIsConfirmationModalShown(true);
                                            }}
                                            label="Submit"
                                            variant="primary"
                                            disabled={isSubmitting || !dirty || !isValid}
                                            isLoading={isSubmitting}
                                        />
                                        <Button
                                            type="reset"
                                            label="cancel"
                                            variant="secondary"
                                            handleClick={() => {
                                                resetForm();
                                                setAction('');
                                            }}
                                        />
                                    </div>
                                </div>
                                <ConfirmationModal
                                    action="fax"
                                    providedValue={values.fax}
                                    onSubmit={submitForm}
                                    open={isConfirmationModalShown}
                                    onClose={() => setIsConfirmationModalShown(false)}
                                />
                            </Form>
                            <Carousel className="pt-4">
                                <CarouselItem showChildren={!!errorMessage}>
                                    <ErrorBlock errorMessage={errorMessage} />
                                </CarouselItem>
                                <CarouselItem showChildren={!!successMessage}>
                                    <SuccessBlock successMessage={successMessage} />
                                </CarouselItem>
                            </Carousel>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default FaxIDCard;
