import { z } from 'zod';

export const faxSchema = z.object({
    fax: z
        .string()
        .regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Invalid fax number'),
    // .refine((val) => /^[0-9]+$/.test(val), {
    //     message: 'Fax number may only contain numbers (0-9)',
    // })
    // .refine((val) => /^[0-9]{10}$/.test(val), {
    //     message: 'Fax number must be 10 digits long',
    // }),
});
