const SuccessCheck = (props: { className: string }) => {
    return (
        <svg
            className={'flex-no-shrink h-5 w-5 fill-current stroke-BrightGreen ' + props.className}
            viewBox="0, 0, 50, 50"
        >
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
            <path fill="none" strokeWidth="4" d="m14.1 27.2 7.1 7.2 16.7-16.8" />
        </svg>
    );
};

export default SuccessCheck;
