import Gear from '../Icons/Gear';

export type TechnicalIssueFullScreenPropTypes = {
    errorMessage: string | undefined;
};

const TechnicalIssueFullScreen = ({ errorMessage }: TechnicalIssueFullScreenPropTypes) => {
    return (
        <div className="absolute flex items-center h-screen w-screen justify-center items-center">
            <div>
                <div className="space-y-2 border-MediumGray !p-8 border bg-white">
                    <div className="mb-[18px]">
                        <h4 className="text-lg font-bold tracking-normal text-TextDarkGrayComp">
                            Sorry, there&apos;s a technical issue
                        </h4>
                    </div>
                    <p className="leading-22 text-TextDarkGrayComp">
                        This content did not load. Try refreshing your browser or coming back later.
                    </p>

                    {errorMessage && (
                        <p className="font-bold tracking-normal text-TextDarkGrayComp">
                            Error: {errorMessage}
                        </p>
                    )}
                    <div className="flex justify-center">
                        <Gear className="pt-4" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnicalIssueFullScreen;
