import { useQuery } from '@tanstack/react-query';

// import { highmarkContactInfoOutput } from "../schemas";
// import { idCardBase64 } from "../utils/idCard";
import { IDCardOptions } from '../types';
import { getErrorMessage } from '../utils/errorHandler';
import httpClient from '../utils/axios';

type GetIdCardParams = {
    idCardType: IDCardOptions;
    memberId: string;
    groupNumber: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

type GetIdCardOutput = {
    idCardImageData: string;
};

export const getIdCard = async ({
    idCardType,
    memberId,
    groupNumber,
    firstName,
    lastName,
    dateOfBirth,
}: GetIdCardParams): Promise<GetIdCardOutput> => {
    try {
        const { data } = await httpClient.request({
            method: 'get',
            url: '/image',
            params: {
                groupNumber,
                cardType: idCardType,
                memberId,
                firstName,
                lastName,
            },
            headers: {
                Accept: 'image/jpeg',
                'X-DOB': dateOfBirth,
            },
        });

        // TODO: what does this look like if not found?
        return data;
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

export const useGetIdCard = (
    queryParams: GetIdCardParams,
    queryOptions: { enabled: boolean; retry?: number }
) => {
    // const { idCardType, memberId, groupNumber } = queryParams;
    const { enabled, retry } = queryOptions;

    return useQuery({
        queryKey: ['getIdCard', queryParams],
        queryFn: () => getIdCard(queryParams),
        enabled,
        retry: retry || 0,
    });
};
