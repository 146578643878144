import { IDCardOptions } from '../types';
import { IdCardContext } from '../context/IdCardContext';
import { Member } from '../context/types';
import { MemberContext } from '../context/MemberContext';
import { getErrorMessage } from '../utils/errorHandler';
import httpClient from '../utils/axios';
import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

type AlternateAddress = {
    hasAlternateAddress: boolean;
    alternateAddress: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        zipCode: string;
    };
};

type PostOrderIDCardParams = (Member | undefined) & { idCardType: IDCardOptions } & {
    alternateAddress: AlternateAddress | undefined;
};

export const postOrderIDCard = async (params: PostOrderIDCardParams) => {
    const { umi, groupNumber, firstName, lastName, dateOfBirth, idCardType, alternateAddress } =
        params;

    const data = {
        umi,
        groupNumber: groupNumber[idCardType],
        firstName,
        lastName,
        dateOfBirth,
        idCardType,
        alternateAddress: alternateAddress?.alternateAddress,
    };

    try {
        await httpClient({
            method: 'post',
            url: 'order',
            data,
        });
    } catch (e) {
        console.error(e);
        throw new Error(getErrorMessage(e));
    }
};

export const useOrderIDCard = () => {
    const { member } = useContext(MemberContext);
    const { idCardType } = useContext(IdCardContext);

    return useMutation({
        mutationKey: ['postOrderIDCard'],
        mutationFn: (params: AlternateAddress) => {
            if (!member) throw new Error('useOrderIDCard: Member not found');
            return postOrderIDCard({
                ...member,
                idCardType,
                alternateAddress: params,
            });
        },
    });
};
