const AnimateAbsolute = ({ showChildren, children }: any) => {
    return (
        <div className={`relative w-full`}>
            <div
                className={`absolute transition-all w-full z-1 ${
                    showChildren
                        ? 'opacity-100 top-0 duration-700 ease-out delay-100 visible'
                        : '-top-[40px] opacity-0 duration-100 ease-in collapse'
                }`}
            >
                {children}
            </div>
        </div>
    );
};

/** Will respect the height of the content and shift adjacent non-absolute items to fit  */
const AnimateGrid = ({ showChildren, children }: any) => {
    return (
        <div style={{ gridArea: '1 / 1' }} className={`w-full h-fit`}>
            <div
                className={`!transition-all w-full z-1 ${
                    showChildren
                        ? '!opacity-100 !translate-y-0 !duration-700 !ease-out !delay-100 !visible'
                        : '!-translate-y-6 !opacity-0 !duration-100 !ease-in !collapse'
                }`}
            >
                {children}
            </div>
        </div>
    );
};

export { AnimateAbsolute, AnimateGrid };
export default AnimateGrid;
