import { z } from 'zod';
import { idCardPropsSchema, viewCardSchema } from '../../schemas';
import { UseQueryResult } from '@tanstack/react-query';

export const productTypeCodeSchema = z.string(z.enum(['M', 'V', 'D', 'RX', 'L', '']));

export const memberInfoOutputProductSchema = z.object({
    productNumber: z.string(),
    productTypeCode: productTypeCodeSchema,
    benefitPlanType: z.string(),
    benefitPlanName: z.string(),
    coverageBeginDate: z.string(),
    coverageCancelDate: z.string(),
    coverageConBeginDate: z.string(),
    coverageEndDate: z.string(),
    effectiveToDate: z.string(),
    effectiveFromDate: z.string(),
    alphaPrefix: z.string(),
});

export const memberInfoPlanTypeSchema = z.enum([
    'Medical',
    'Vision',
    'Dental',
    'Drug',
    'Wellness Programs',
    '',
]);

export const member = z.object({
    ecId: z.string(),
    pinNumber: z.string(),
    coveredIndividualId: z.string(),
    relationship: z.string().nullable(),
    relationshipCode: z.string().nullable(),
    firstName: z.string(),
    middleName: z.string().nullable(),
    lastName: z.string(),
    suffix: z.string().nullable(),
    birthdate: z.string(),
    coverageCancellationDate: z.string(),
    plans: z.array(
        z.object({
            planType: memberInfoPlanTypeSchema,
            clientId: z.string(),
            clientNumber: z.string(),
            clientName: z.string(),
            groupNumber: z.string(),
            isNDMedicaid: z.boolean(),
            keyProduct: memberInfoOutputProductSchema,
            productTypes: z.array(z.string()),
            products: z.array(memberInfoOutputProductSchema),
        })
    ),
});

export const contactPreferenceOptions = z.enum(['IE', 'UM', 'HP', 'WP', 'MP', 'NN', 'O', 'Y', '']);

// export const highmarkContactInfoSchema = z.object({
//     workEmailAddress: z.string(),
//     address1: z.string(),
//     address2: z.string(),
//     address3: z.string(),
//     address4: z.string(),
//     city: z.string(),
//     county: z.string(),
//     state: z.string(),
//     zip: z.string(),
//     paperlessInd: z.boolean(),
//     selectedAlertNotification: contactPreferenceOptions,
//     selectedDental: contactPreferenceOptions,
//     selectedEOB: contactPreferenceOptions,
//     selectedEOC: contactPreferenceOptions,
//     selectedFormulary: contactPreferenceOptions,
//     selectedHealthNurseOutreach: contactPreferenceOptions,
//     selectedOtherProdServ: contactPreferenceOptions,
//     selectedPartB: contactPreferenceOptions,
//     selectedPartD: contactPreferenceOptions,
//     selectedProviderDirectory: contactPreferenceOptions,
//     selectedWellness: contactPreferenceOptions,
// });

export const engenContactInfoSchemaV2 = z.object({
    type: z.string(),
    line1: z.string(),
    line2: z.string(),
    line3: z.string(),
    line4: z.string(),
    city: z.string(),
    state: z.string(),
    postalCode: z.string(),
    countryCode: z.string(),
    county: z.string(),
    countyCode: z.string(),
    countyDesc: z.string(),
});

export type EngenContactInfoSchemaV2 = z.infer<typeof engenContactInfoSchemaV2>;

export type Action = 'order' | 'print' | 'email' | 'fax' | 'report' | '';

export interface BaseIdCardProps {
    action?: Action;
    setAction: (_: Action) => void;
}

export const Schema = z.object({
    Address1: z
        .string({
            required_error: 'Address is required',
            invalid_type_error: 'Address must be a string',
        })
        .min(1),
    Address2: z.string().optional(),
    City: z
        .string({
            required_error: 'City is required',
            invalid_type_error: 'City must be a string',
        })
        .min(1),
    State: z.enum(
        [
            'AL',
            'AK',
            'AS',
            'AZ',
            'AR',
            'CA',
            'CO',
            'CT',
            'DE',
            'DC',
            'FM',
            'FL',
            'GA',
            'GU',
            'HI',
            'ID',
            'IL',
            'IN',
            'IA',
            'KS',
            'KY',
            'LA',
            'ME',
            'MH',
            'MD',
            'MA',
            'MI',
            'MN',
            'MS',
            'MO',
            'MT',
            'NE',
            'NV',
            'NH',
            'NJ',
            'NM',
            'NY',
            'NC',
            'ND',
            'MP',
            'OH',
            'OK',
            'OR',
            'PW',
            'PA',
            'PR',
            'RI',
            'SC',
            'SD',
            'TN',
            'TX',
            'UT',
            'VT',
            'VI',
            'VA',
            'WA',
            'WV',
            'WI',
            'WY',
        ],
        {
            required_error: 'State is required',
            invalid_type_error: 'Invalid Selection',
        }
    ),
    ZipCode: z
        .string({
            required_error: 'Zipcode is required',
        })
        .superRefine((val, ctx) => {
            // we're using super-refine because we want to insert a 'type' error
            // explaining that it needs to be a number, even though it's a string.
            if (/[^0-9-]/.test(val)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Zipcode can only contain numbers 0 to 9',
                });
            }

            if (!/^[0-9]{5}$/.test(val)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Zipcode must be a five digit numeric code',
                });
            }

            // TODO: consider adding this back if Engen data is better; in dev, it passes "563010000" and this fails
            // if (!/^[0-9]{5}$/.test(val)) {
            //     ctx.addIssue({
            //         code: z.ZodIssueCode.custom,
            //         message: 'Zipcode must be a five digit numeric code',
            //     });
            // }
        }),
});

export type ValuesType = z.infer<typeof Schema>;

const addressValidationInstance = z.object({
    analysis: z.object({
        dpv_match_code: z.enum(['Y', 'N', 'S', 'D']).nullish(),
        dpv_footnotes: z.string().nullish(), // This is a string of 2 character codes
        footnotes: z.string().nullish(),
        // There is a lot more information available here, but
        // we're not using it right now.
    }),
    candidate_index: z.number(), // input can have multiple possible matches
    components: z.object({
        primary_number: z.string().nullish(),
        street_name: z.string().nullish(),
        street_postdirection: z.string().nullish(),
        street_suffix: z.string().nullish(),
        city_name: z.string().nullish(),
        default_city_name: z.string().nullish(),
        state_abbreviation: z.string().nullish(),
        zipcode: z.string().nullish(),
        plus4_code: z.string().nullish(),
        delivery_point: z.string().nullish(),
        delivery_point_check_digit: z.string().nullish(),
    }),
    delivery_line_1: z.string(),
    input_id: z.string().nullish(), // should be the same as what we input. Default blank.
    input_index: z.number(), // always 0 if we submitted a single address
    last_line: z.string(), // usually the city, state, zip.
    metadata: z.object({
        record_type: z.enum(['F', 'G', 'H', 'P', 'R', 'S', '']).nullish(), // blank indicates no DPV match
    }),
});

export type AddressValidationInstance = z.infer<typeof addressValidationInstance>;

export const states = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

// SEE: https://www.smarty.com/docs/cloud/us-street-api#footnotes
export const SMARTY_ERROR_CODES = [
    { code: 'A#', details: 'Corrected ZIP code' },
    { code: 'B#', details: 'Corrected city/state spelling' },
    { code: 'C#', details: 'Invalid city/state/ZIP' },
    { code: 'D#', details: 'No ZIP+4 assigned' },
    { code: 'E#', details: 'Multiple records returned with same 5-digit ZIP' },
    { code: 'F#', details: 'Address not found in city/state/ZIP' },
    { code: 'G#', details: 'Used addressee data' },
    { code: 'H#', details: 'Missing secondary number' },
    { code: 'I#', details: 'Insufficient/ incorrect address data' },
    { code: 'J#', details: 'Dual address' },
    { code: 'K#', details: 'Cardinal rule match' },
    { code: 'L#', details: 'Changed address component' },
    { code: 'LL#', details: 'Flagged address for LACSLink' },
    { code: 'LI#', details: 'Flagged address for LACSLink' },
    { code: 'M#', details: 'Corrected street spelling' },
    { code: 'N#', details: 'Fixed abbreviations' },
    { code: 'O#', details: 'Multiple ZIP+4; lowest used' },
    { code: 'P#', details: 'Better address exists' },
    { code: 'Q#', details: 'Unique ZIP match' },
    { code: 'R#', details: 'No match; EWS: Match soon' },
    { code: 'S#', details: 'Unrecognized secondary address' },
    { code: 'T#', details: 'Multiple response due to magnet street syndrome' },
    { code: 'U#', details: 'Unofficial city name' },
    { code: 'V#', details: 'Unverifiable city/state' },
    { code: 'W#', details: 'Invalid delivery address' },
    { code: 'X#', details: 'Unique ZIP Code' },
    { code: 'Y#', details: 'Military match' },
    { code: 'Z#', details: 'Matched with ZIPMOVE' },
];

export type IdCardProps = z.infer<typeof idCardPropsSchema>;

export type ViewCard = z.infer<typeof viewCardSchema>;

export type IdCardQuery = UseQueryResult<{ idCardImageData: string }, unknown>;
export type ContactInfoQuery = UseQueryResult<EngenContactInfoSchemaV2 | undefined, unknown>;
