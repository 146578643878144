import { Form, Formik } from 'formik';
import { FormikSearchMember, SearchMember } from '../types/types';
import { header, subHeader } from '../constants/typography';
import { useContext, useEffect, useState } from 'react';

import Button from '../components/Button/Button';
import Down from '../components/Icons/Down';
import FieldInput from '../components/FieldInput/FieldInput';
import { MemberContext } from '../context/MemberContext';
import { formikMemberSchema } from '../schemas/index';
import { getFieldId } from '../components/FieldInput/form-base';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useGetMembers } from '../api/getMembers';
import { IdCardContext } from '../context/IdCardContext';
import { equals } from 'ramda';

const inputStyle =
    'font-bold outline-3 outline-offset-2 outline-ToneBlue pb-[12px] pl-6 pr-[12px] pt-6 text-DarkGray w-full';

const initialFormValues: FormikSearchMember = {
    firstName: '',
    lastName: '',
    month: '',
    day: '',
    year: '',
    searchField: '',
    searchValue: '',
};

const initialFormFields: SearchMember = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    UMI: '',
    MBI: '',
    groupNumber: '',
};

const MemberSearch = () => {
    const [showAdvancedFields, setShowAdvancedFields] = useState(false);
    const [formFields, setFormFields] = useState<SearchMember>(initialFormFields);
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [advancedFieldError, setAdvancedFieldError] = useState<string>('');
    const [dateError, setDateError] = useState<string>('');

    const { setMember, setMembers, setHasSearched } = useContext(MemberContext);
    const { reset: resetIdCardContext } = useContext(IdCardContext);

    const {
        data,
        isSuccess,
        isLoading,
        error: memberSearchError,
    } = useGetMembers(
        {
            firstName: formFields.firstName,
            lastName: formFields.lastName,
            dateOfBirth: formFields.dateOfBirth,
            UMI: formFields.UMI || undefined,
            MBI: formFields.MBI || undefined,
            groupNumber: formFields.groupNumber || undefined,
        },
        {
            enabled:
                shouldSubmit &&
                !!formFields.firstName &&
                !!formFields.lastName &&
                !!formFields.dateOfBirth,
        }
    );

    useEffect(() => {
        if (data && isSuccess) {
            setHasSearched(true);

            if (data.length === 0) {
                setMember(undefined);
                setMembers([]);
            }

            if (data.length === 1) {
                setMember(data[0]);
                // setMembers([]); // allows ability to keep filtering based on groupNumber
            }

            if (data.length > 1) {
                setMember(undefined);
                setMembers(data);
                setShowAdvancedFields(true);
            }
        }
    }, [data, isSuccess, setMember, setMembers, setHasSearched]);

    return (
        <div className="bg-ToneBlue flex h-fit grow-0 lg:w-3/12">
            <Formik
                validationSchema={toFormikValidationSchema(formikMemberSchema)}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialFormValues}
                onSubmit={(values, actions) => {
                    const { validateForm, setSubmitting } = actions;
                    validateForm();

                    let hasAdvancedFieldError = false;
                    let trimmedSearchValue = '';

                    if (values.searchField && values.searchValue) {
                        const { searchField, searchValue } = values;
                        trimmedSearchValue = searchValue.trim();

                        if (searchField === 'Group Number' && trimmedSearchValue.length !== 8) {
                            hasAdvancedFieldError = true;
                            setAdvancedFieldError('Group Number must be 8 characters');
                        } else if (
                            searchField === 'UMI' &&
                            (trimmedSearchValue.length < 12 || trimmedSearchValue.length > 13)
                        ) {
                            hasAdvancedFieldError = true;

                            setAdvancedFieldError('UMI must be 12 or 13 characters');
                        } else if (searchField === 'MBI' && trimmedSearchValue.length !== 11) {
                            hasAdvancedFieldError = true;
                            setAdvancedFieldError('MBI must be 11 characters');
                        } else {
                            setAdvancedFieldError('');
                        }
                    }

                    if (hasAdvancedFieldError) {
                        setSubmitting(false);
                        setTimeout(() => setAdvancedFieldError(''), 5000);
                        return;
                    }

                    const { firstName, lastName, month, day, year, searchField } = values;

                    const monthNum = parseInt(month);
                    const dayNum = parseInt(day);
                    const yearNum = parseInt(year);

                    const currentYear = new Date().getFullYear();

                    if (
                        monthNum < 0 ||
                        monthNum > 12 ||
                        dayNum < 0 ||
                        dayNum > 31 ||
                        yearNum < 1900 ||
                        yearNum > currentYear
                    ) {
                        setSubmitting(false);
                        setDateError('Please enter a valid date');
                        setTimeout(() => setDateError(''), 5000);
                        return;
                    }

                    const dateOfBirth = `${year}-${month}-${day}`;

                    let inputParams = {
                        firstName,
                        lastName,
                        dateOfBirth,
                        [(searchField === 'Group Number' ? 'groupNumber' : searchField) || '']:
                            searchField && trimmedSearchValue ? trimmedSearchValue : undefined,
                    };

                    const didFieldsChange = !equals(inputParams, formFields);
                    const didLastSearchFail = !!memberSearchError;
                    if (!didFieldsChange && !didLastSearchFail) {
                        console.warn('Search fields have not changed');
                        setSubmitting(false);
                        return;
                    }

                    setFormFields(inputParams);
                    setShouldSubmit(true);
                    setSubmitting(false);
                    resetIdCardContext(); // could also reset the member context here to with setMember(undefined)
                }}
            >
                {({ handleReset, isSubmitting, setFieldValue }) => (
                    <>
                        <Form className="flex w-full">
                            <div className="flex w-full flex-col p-6">
                                <div className="flex w-full flex-col gap-6">
                                    <h1 className={`${header} text-White`}>Search for a Member</h1>
                                    <FieldInput
                                        id={getFieldId('firstName', 'First Name')}
                                        label="First Name"
                                        placeholder="*First Name"
                                        name="firstName"
                                        required
                                        type="text"
                                        tailwindStyle={inputStyle}
                                        variant="inverted"
                                    />
                                    <FieldInput
                                        id={getFieldId('lastName', 'Last Name')}
                                        label="Last Name"
                                        placeholder="*Last Name"
                                        name="lastName"
                                        required
                                        type="text"
                                        tailwindStyle={inputStyle}
                                        variant="inverted"
                                    />
                                    <div className="mt-10">
                                        <p className="text-white font-bold">Date of Birth</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <div className="basis-1/4 grow">
                                            <FieldInput
                                                id={getFieldId('month', 'month')}
                                                label="Month"
                                                placeholder="MM"
                                                name="month"
                                                required
                                                type="text"
                                                tailwindStyle={inputStyle}
                                                variant="inverted"
                                                maxLength={2}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue('month', value);
                                                    if (value.length === 2) {
                                                        document
                                                            .getElementById(
                                                                getFieldId('day', 'day')
                                                            )
                                                            ?.focus();
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="basis-1/4 grow">
                                            <FieldInput
                                                id={getFieldId('day', 'day')}
                                                label="Day"
                                                placeholder="DD"
                                                name="day"
                                                required
                                                type="text"
                                                tailwindStyle={inputStyle}
                                                variant="inverted"
                                                maxLength={2}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue('day', value);
                                                    if (value.length === 2) {
                                                        document
                                                            .getElementById(
                                                                getFieldId('year', 'year')
                                                            )
                                                            ?.focus();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="basis-1/3 grow">
                                            <FieldInput
                                                id={getFieldId('year', 'year')}
                                                label="Year"
                                                placeholder="YYYY"
                                                name="year"
                                                required
                                                type="text"
                                                tailwindStyle={inputStyle}
                                                variant="inverted"
                                            />
                                        </div>
                                    </div>
                                    {dateError && (
                                        <p className="text-white font-bold mt-2">{dateError}</p>
                                    )}
                                    {/* Advanced Fields */}
                                    <div className="mt-16 flex flex-col gap-6">
                                        <div>
                                            <h1
                                                className={`${subHeader} flex flex-row items-center justify-between text-white`}
                                                onClick={() =>
                                                    setShowAdvancedFields(
                                                        (previouslyShowing) => !previouslyShowing
                                                    )
                                                }
                                            >
                                                <span>Advanced Search</span>
                                                <div
                                                    className={`items center flex flex-row justify-center cursor-pointer px-2 py-3 transition-all duration-200 ${
                                                        showAdvancedFields ? 'rotate-180' : ''
                                                    }`}
                                                >
                                                    <Down className="max-h-4 box-border" />
                                                </div>
                                            </h1>
                                        </div>
                                        {showAdvancedFields && (
                                            <div>
                                                <p className="text-white">
                                                    Please add information for one of these fields
                                                </p>
                                                <div className="flex flex-col gap-6">
                                                    <FieldInput
                                                        id={getFieldId(
                                                            'searchField',
                                                            'searchField'
                                                        )}
                                                        label=""
                                                        name="searchField"
                                                        required
                                                        type="select"
                                                        tailwindStyle="relative w-full appearance-none rounded-none border-3 border-CoolGray bg-[url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+Cgk8cGF0aCBkPSJNNywxMGw1LDVsNS01SDd6IiAvPgo8L3N2Zz4=)] text-base bg-[100%] bg-right-[16px] bg-no-repeat pb-4 pl-6 pr-18 pt-6 font-bold capitalize text-DarkGray outline-3 outline-ToneBlue"
                                                        placeholder="-Select-"
                                                        selectOptions={[
                                                            'Group Number',
                                                            'UMI',
                                                            'MBI',
                                                        ]}
                                                        variant="inverted"
                                                    />
                                                    <FieldInput
                                                        id={getFieldId(
                                                            'searchValue',
                                                            'searchValue'
                                                        )}
                                                        label=""
                                                        placeholder="123456789"
                                                        name="searchValue"
                                                        required
                                                        type="text"
                                                        tailwindStyle={inputStyle}
                                                        variant="inverted"
                                                    />
                                                </div>
                                                {advancedFieldError && (
                                                    <p className="text-white font-bold mt-2">
                                                        {advancedFieldError}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mt-14 flex flex-col justify-center">
                                    <Button
                                        type="submit"
                                        label="Search"
                                        variant="secondary"
                                        disabled={isSubmitting || isLoading}
                                        isLoading={isSubmitting || isLoading}
                                    />

                                    <Button
                                        type="reset"
                                        label="Reset"
                                        variant="link"
                                        handleClick={() => {
                                            handleReset();
                                            setFormFields(initialFormFields);
                                            setShouldSubmit(false);
                                            setMember(undefined); // clear member in context
                                            setMembers([]);
                                            setHasSearched(false);
                                            setShowAdvancedFields(false);
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default MemberSearch;
